import UsersGrid from '../../components/UsersGrid';
import { Box, Button, Container, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import queryString from 'query-string';
import CreateAccountDialog from '../../components/Dialogs/CreateAccountDialog';
import { useSelector } from 'react-redux';

export default function Clients() {
    const accountInfo = useSelector(state => state.account);
    const isAdmin = accountInfo.account_type === 10;
    const location = useLocation();
    const navigate = useNavigate();
    const [qs, setQs] = useState({p: 1, size: 25, sc: 'id', sd: 'desc', q: ''});
    const [modal, setModal] = useState(null);

    useEffect(() => {
        const q = queryString.parse(location.search);
        setQs({
            p: parseInt(q.p) || 1,
            size: parseInt(q.size) || 25,
            sc: q.sc || 'id',
            sd: q.sd || 'desc',
            q: q.q || ''
        });
    }, [location]);

    const updateQs = (newQs) => {
        const newUrl = './?' + queryString.stringify(newQs);
        console.log('[updateQs] newUrl', newUrl);
        setQs(newQs);
        navigate(newUrl, {replace: true});
    };

    const Search = <TextField
        variant="outlined" label="Поиск по базе" size="medium"
        sx={{mb: 2, mt: 1, width: 350, maxWidth: '80%'}}
        onChange={e => {
            updateQs({...qs, q: e.currentTarget.value});
        }}
        value={qs.q}
    />;

    const createAccount = () => {
        const onCancel = () => {
            setModal(null);
        };

        const onSuccess = (id) => {
            setModal(null);
            navigate('./' + id);
        };

        setModal(<CreateAccountDialog initialAccountType={1} onCancel={onCancel} onSuccess={onSuccess}/>);
    };

    return (
        <Container component="main" maxWidth="xl">
            {modal}
            <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexWrap: 'wrap'}}>
                <h1 style={{marginBottom: 20, marginTop: 20}}>Клиенты</h1>
                {isAdmin && <Button variant="contained" onClick={createAccount}>Новый аккаунт</Button>}
            </Box>
            {Search}
            <UsersGrid accountType={1} qs={qs} updateQs={updateQs}/>
        </Container>
    );
}