import { Box, Chip, Container, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Alert } from '@mui/lab';
import { parse } from 'date-fns';

const zero = (i) => `0${i}`.slice(-2);

const generateTimes = () => {
    let times = [];
    let timesMap = {};
    let timesI = [];
    for (let i = 420; i <= 1200; i += 30) {
        const hours = zero(Math.floor(i / 60));
        const minutes = zero(i % 60);
        const t = `${hours}:${minutes}`;
        const d = `${zero(Math.floor(i / 60) - 5)}:${minutes}` + ':00+00';
        times.push({i, d, t});
        timesI.push(i);
        timesMap[i] = {i, d, t};
    }
    return [times, timesMap, timesI];
};

export default function TimeSelector(
    {
        onChange,
        initialData = {
            start_time: '03:00:00+00',
            end_time: '12:00:00+00',
            excepts: ['07:00:00+00', '07:30:00+00']
        }
    }
) {
    const [times, timesMap, timesI] = generateTimes();

    const [data, setData] = useState(initialData);
    const [selectedTimes, setSelectedTimes] = useState([]);

    useEffect(() => {
        const i1 = times.filter(t => data.start_time === t.d)[0].i;
        const i2 = times.filter(t => data.end_time === t.d)[0].i;
        const activeTimes = times.filter(time => !data.excepts.includes(time.d) && time.i >= i1 && time.i <= i2).map(i => i.i);
        setSelectedTimes(activeTimes);
    }, [data]);

    const onSelectTime = (time) => {
        const s = (selectedTimes.includes(time.i) ? selectedTimes.filter(i => i !== time.i) : [...selectedTimes, time.i]).sort((a, b) => a - b);
        const startTime = timesMap[s[0]];
        const endTime = timesMap[s.pop()];
        const excepts = timesI.filter(i => !s.includes(i) && i > startTime.i && i < endTime.i).map(i => timesMap[i].d);
        const d = {start_time: startTime.d, end_time: endTime.d, excepts};
        console.log('time onChange', d);
        setData(d);
        onChange(d);
    };

    return (
        <Box sx={{mt: 1, mb: 2}}>
            <Typography sx={{mt: 2}} variant="body2"><b>Часовой пояс: Алматы (GMT+5)</b></Typography>
            <Box sx={{mt: 1.5, mb: 2}}>
                {times.map(time => {
                    // const isSelected = time.d
                    return <Chip
                        label={time.t} key={time.i}
                        color={selectedTimes.includes(time.i) ? 'primary' : 'default'}
                        variant={selectedTimes.includes(time.i) ? 'filled' : 'outlined'}
                        onClick={() => onSelectTime(time)}
                        sx={{m: .5}}
                    />;
                })}

            </Box>
            <Typography variant="h6" sx={{mb: 0.5}}>Итог:</Typography>
            <Alert severity="info">
                <Typography><b>Рабочее время: </b>
                    с {times.filter(t => data.start_time === t.d)[0].t} до {times.filter(t => data.end_time === t.d)[0].t}
                </Typography>
                <Typography>
                    <b>Перерывы: </b>
                    {data.excepts.map(i => times.filter(t => i === t.d)[0].t).join(', ')}
                </Typography>
            </Alert>
        </Box>
    );
}
