import { Box, Container, TextField, Typography, Button, Alert } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { editMyProfile, getMyAccountInfo } from '../../utils/api';
import ChangePasswordDialog from '../../components/Dialogs/ChangePasswordDialog';

export default function Account() {
    const dispatch = useDispatch();
    const accountInfo = useSelector(state => state.account);
    const [modal, setModal] = useState(null);
    const [error, setError] = useState(null);
    const [data, setData] = useState(accountInfo);
    const [displaySaveButton, setDisplaySaveButton] = useState(false);

    const accountTypes = {
        1: 'Аккаунт клиента',
        5: 'Аккаунт сотрудника',
        10: 'Аккаунт администратора'
    };

    useEffect(() => {
        getMyAccountInfo()
            .then(r => {
                setData(r.data);
                dispatch({type: 'SET_ACCOUNT_DATA', payload: r.data});
            });
    }, []);

    const onChange = (id) => {
        return (e) => {
            setDisplaySaveButton(true);
            setData({...data, [id]: e.currentTarget.value});
        };
    };

    const openPasswordDialog = () => {
        const onCancel = () => {
            setModal(null);
        };

        const onSuccess = () => {
            setModal(null);
            setTimeout(() => {
                window.localStorage.access_token = 'refresh';
                window.location.reload();
            }, 1500);
        };

        setModal(<ChangePasswordDialog onCancel={onCancel} onSuccess={onSuccess} isAdmin={false} id={data.id}/>);
    };

    const save = () => {
        if (!data.first_name || data.first_name?.length < 1) return setError('Не указано имя');
        if (!data.last_name || data.last_name?.length < 1) return setError('Не указана фамилия');
        if (!data.phone || data.phone?.length < 3) return setError('Не указан номер телефона');

        const parsedMail = (data.email.match(/.+@.+\..+/gmi) || [])[0] || null;
        if (!parsedMail) return setError('Некорректный адрес Email');

        setError(null);
        editMyProfile(data)
            .then(r => {
                console.log('[save]', r);
                setData(r.data);
                setDisplaySaveButton(false);
                dispatch({type: 'SET_ACCOUNT_DATA', payload: r.data});
                dispatch({type: 'OPEN_SNACKBAR', severity: 'success', message: 'Изменения сохранены!'});
            })
            .catch(err => {
                console.error(err);
            });
    };

    return (
        <Container component="main" maxWidth="lg">
            {modal}
            <h1>Мой аккаунт</h1>
            <Typography sx={{mb: 1}}>ID: {data.id}</Typography>
            <Typography>{accountTypes[data.account_type]} (LVL {data.account_type})</Typography>
            <Box sx={{
                '& .MuiTextField-root': {
                    mr: 1.5, ml: 0, mt: 1, mb: 1,
                    width: {lg: '25%', md: '40%', xs: '100%'}
                },
                mt: 3,
                mb: 2
            }}>
                <TextField label="Фамилия" variant="outlined" value={data.last_name} required
                           onChange={onChange('last_name')}/>
                <TextField label="Имя" variant="outlined" value={data.first_name} required
                           onChange={onChange('first_name')}/>
                <TextField label="Отчество" variant="outlined" value={data.patronymic}
                           onChange={onChange('patronymic')}/>
            </Box>

            <Box sx={{
                '& .MuiTextField-root': {
                    mr: 1.5, ml: 0, mt: 1, mb: 1,
                    width: {lg: '38%', md: '82%', xs: '100%'}
                },
                mb: 3
            }}>
                <TextField label="Email" variant="outlined" value={data.email} required
                           onChange={onChange('email')}/>
                <TextField label="Телефон" variant="outlined" value={data.phone} required
                           onChange={onChange('phone')}/>
            </Box>

            <Button sx={{mb: 2, display: 'block'}} variant="outlined" onClick={openPasswordDialog}>
                Изменить пароль
            </Button>

            {displaySaveButton && <Button variant="contained" onClick={save}>Сохранить</Button>}
            {error && <Alert severity="error" sx={{mt: 3, mb: 1, maxWidth: 800}}>{error}</Alert>}
        </Container>
    );
}