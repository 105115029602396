const parseLocalStorage = (i) => {
    const data = localStorage.getItem(i);
    if (data) {
        try {
            return JSON.parse(data);
        } catch (e) {
            return null;
        }
    } else {
        return null;
    }
};

const initialState = {
    'staff': parseLocalStorage('appointment_staff'),
    'service': parseLocalStorage('appointment_service'),
    'time': parseLocalStorage('appointment_time'),
    'comment': ''
};

export const appointmentReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'APPOINTMENT_SET_STAFF':
            localStorage.setItem('appointment_staff', JSON.stringify(action.payload));
            localStorage.removeItem('appointment_service');
            localStorage.removeItem('appointment_time');

            return { staff: action.payload, service: null, time: null };

        case 'APPOINTMENT_CLEAR_STAFF':
            localStorage.removeItem('appointment_staff');
            localStorage.removeItem('appointment_service');
            localStorage.removeItem('appointment_time');

            return { staff: null, service: null, time: null };

        case 'APPOINTMENT_SET_SERVICE':
            localStorage.setItem('appointment_service', JSON.stringify(action.payload));
            localStorage.removeItem('appointment_time');

            return { ...state, service: action.payload, time: null };

        case 'APPOINTMENT_CLEAR_SERVICE':
            localStorage.removeItem('appointment_service');
            localStorage.removeItem('appointment_time');

            return { ...state, service: null, time: null };

        case 'APPOINTMENT_SET_TIME':
            // localStorage.setItem('appointment_time', JSON.stringify(action.payload));

            return { ...state, time: action.payload };

        case 'APPOINTMENT_SET_COMMENT':
            return { ...state, comment: action.payload };

        case 'APPOINTMENT_CLEAR_TIME':
            localStorage.removeItem('appointment_time');

            return { ...state, time: null };

        case 'CLEAR_APPOINTMENT_DATA':
            localStorage.removeItem('appointment_staff');
            localStorage.removeItem('appointment_service');
            localStorage.removeItem('appointment_time');

            return { staff: null, service: null, time: null };

        default:
            return state;
    }
};