import { useEffect, useState } from 'react';
import { CustomDataGrid } from '../CustomDataGrid';
import { searchUsers } from '../../utils/api';
import { useNavigate } from 'react-router-dom';
import queryString from 'query-string';
import { format, parse } from 'date-fns';
import { Avatar } from '@mui/material';

export default function UsersGrid({accountType = 10, qs, updateQs}) {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    const [rows, setRows] = useState([]);
    const [total, setTotal] = useState(undefined);
    const [sortModel, setSortModel] = useState([]);

    useEffect(() => {
        updateDataGrid();
    }, [qs]);

    const updateDataGrid = () => {
        setIsLoading(true);
        console.log('NEW QS', qs);
        const offset = (parseInt(qs.p) - 1) * parseInt(qs.size);
        console.log('OFFSET', offset);
        let params = {
            count: qs.size,
            offset,
            q: qs.q,
            sc: qs.sc !== 'disabled' ? qs.sc : undefined,
            sd: qs.sd === 'asc' ? 1 : qs.sd === 'desc' ? 0 : undefined,
            account_type: accountType
        };

        if (qs.sc && qs.sc !== 'disabled' && qs.sd) setSortModel([{field: qs.sc, sort: qs.sd}]);
        else setSortModel([]);

        searchUsers(params)
            .then(r => {
                console.log('[DataGrid]:', r.data);
                setRows(r.data.rows);
                setTotal(r.data.total);
                setIsLoading(false);
            })
            .catch(err => {
                console.error('[DataGrid]:', err.response);
                setIsLoading(false);
            });
    };

    const columns = [
        {field: 'id', headerName: 'ID', width: 130},
        {
            field: 'last_name', headerName: 'ФИО', width: 400,
            renderCell: ({row}) => {
                return (
                    <>
                        <Avatar sx={{width: 30, height: 30, mr: 1.5}}/>
                        {row.last_name} {row.first_name} {row.patronymic}
                    </>
                );
            }
        },
        {field: 'phone', headerName: 'Телефон', width: 200, sortable: false},
        {
            field: 'register_date',
            headerName: 'Дата регистрации',
            width: 200,
            valueFormatter: (params) => {
                const date = new Date(params.value);
                return format(date, 'dd.MM.yyyy');
            }
        },
        {field: 'email', headerName: 'Email', width: 200, sortable: false}
    ];

    const onPageChange = (page) => {
        console.log('[DataGrid]: onPageChange', page);
        updateQs({...qs, p: page + 1});
    };

    const onPageSizeChange = (pageSize) => {
        console.log('[DataGrid]: onPageSizeChange', pageSize);
        updateQs({...qs, size: pageSize});
    };

    const onSortModelChange = (newSortModel) => {
        console.log('[DataGrid]: onSortModelChange', newSortModel);
        const sm = newSortModel[0];
        if (!sm) return updateQs({...qs, sc: 'disabled'});

        updateQs({...qs, sc: sm.field, sd: sm.sort});
    };

    return (
        <CustomDataGrid
            rows={rows}
            columns={columns}
            editable={false}
            autoHeight
            pagination
            page={qs.p - 1}
            pageSize={qs.size}
            rowCount={total}
            rowsPerPageOptions={[25, 50, 100, 150, 200]}
            paginationMode="server"
            onPageChange={onPageChange}
            onPageSizeChange={onPageSizeChange}
            sortingMode="server"
            sortModel={sortModel}
            onSortModelChange={onSortModelChange}
            onRowClick={(row) => navigate('./' + row.id)}
            sx={{'& .MuiDataGrid-row': {cursor: 'pointer'}}}
            loading={isLoading}
        />
    );
}