import {
    Alert,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle, FormControl, InputLabel,
    LinearProgress, MenuItem, Select,
    TextField
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { createService, editService, getService } from '../../utils/api';

export default function ServiceDialog({staffId, serviceId, onCancel, onSuccess}) {
    const dispatch = useDispatch();
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState({title: '', description: '', duration_minutes: 45});

    useEffect(() => {
        if (serviceId) {
            getService(serviceId)
                .then(r => {
                    setLoading(false);
                    setData({
                        title: r.data.title,
                        description: r.data.description,
                        duration_minutes: r.data.duration_minutes
                    });
                });
        } else {
            setData({title: '', description: '', duration_minutes: 45});
            setLoading(false);
        }
    }, [serviceId]);

    const save = () => {
        if (!data.title || data.title?.length === 0) return setError('Введите название');

        console.log('data', data);
        setLoading(true);
        setError(null);

        if (serviceId) {
            editService(serviceId, data)
                .then(r => {
                    console.log('createService', r.data);
                    setLoading(false);
                    dispatch({type: 'OPEN_SNACKBAR', severity: 'success', message: 'Услуга успешно изменена'});
                    onSuccess();
                })
                .catch(err => {
                    console.error(err);
                    setLoading(false);
                    setError(err.response.data.message || 'Произошла ошибка ' + err.response.status);
                });
        } else {
            createService(staffId, data)
                .then(r => {
                    console.log('createService', r.data);
                    setLoading(false);
                    dispatch({type: 'OPEN_SNACKBAR', severity: 'success', message: 'Услуга успешно создана'});
                    onSuccess();
                })
                .catch(err => {
                    console.error(err);
                    setLoading(false);
                    setError(err.response.data.message || 'Произошла ошибка ' + err.response.status);
                });
        }
    };

    const onChange = (id) => {
        return (e) => {
            setData({...data, [id]: e.currentTarget.value});
        };
    };

    return (
        <Dialog open={true} onClose={onCancel} maxWidth="xs" disableEnforceFocus fullWidth>
            <DialogTitle>{serviceId ? 'Редактирование' : 'Создание'} услуги</DialogTitle>
            <DialogContent>
                <TextField
                    label="Название" variant="outlined" margin="dense" fullWidth
                    value={data.title} onChange={onChange('title')}
                />
                <TextField
                    label="Описание" variant="outlined" margin="dense" fullWidth multiline={true} minRows={2}
                    value={data.description} onChange={onChange('description')}
                />
                <FormControl fullWidth sx={{mt: 3, mb: 0}}>
                    <InputLabel id="service-duration-select-label">Длительность</InputLabel>
                    <Select
                        labelId="service-duration-select-label"
                        id="service-duration-select"
                        value={data.duration_minutes}
                        label="Длительность"
                        onChange={(e) => setData({...data, duration_minutes: e.target.value})}
                    >
                        <MenuItem value={15}>15 минут</MenuItem>
                        <MenuItem value={30}>30 минут</MenuItem>
                        <MenuItem value={45}>45 минут</MenuItem>
                        <MenuItem value={60}>60 минут</MenuItem>
                        <MenuItem value={75}>1 час 15 минут</MenuItem>
                        <MenuItem value={90}>1 час 30 минут</MenuItem>
                        <MenuItem value={105}>1 час 45 минут</MenuItem>
                        <MenuItem value={120}>2 часа</MenuItem>
                    </Select>
                </FormControl>
                {error && <Alert severity="error" sx={{mt: 3, mb: 1, maxWidth: 800}}>{error}</Alert>}
            </DialogContent>
            <DialogActions>
                <Button onClick={onCancel}>Отменить</Button>
                <LoadingButton onClick={save} color="primary" variant="contained" loading={loading}>
                    {serviceId ? 'Сохранить' : 'Создать'}
                </LoadingButton>
            </DialogActions>
            {loading && <LinearProgress style={{height: 6}}/>}
        </Dialog>
    );
}