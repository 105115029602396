import { Alert, Box, Button, Container, MenuItem, Paper, Select, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';
import ChangePasswordDialog from '../Dialogs/ChangePasswordDialog';
import { deleteUser, editMyProfile, editUser } from '../../utils/api';
import { useDispatch, useSelector } from 'react-redux';
import { ConfirmationDialog } from '../ConfirmationDialog';
import { useLocation, useNavigate } from 'react-router-dom';

const accountTypes = {
    1: 'Аккаунт клиента',
    5: 'Аккаунт сотрудника',
    10: 'Аккаунт администратора'
};

export default function EditAccount({id, initialData}) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const [data, setData] = useState(initialData);
    const [modal, setModal] = useState(null);
    const [error, setError] = useState(null);
    const [displaySaveButton, setDisplaySaveButton] = useState(false);

    const accountInfo = useSelector(state => state.account);
    const isAdmin = accountInfo.account_type === 10;

    console.log('location', location);

    const onChange = (id) => {
        return (e) => {
            setDisplaySaveButton(true);
            setData({...data, [id]: e.currentTarget.value});
        };
    };

    const openPasswordDialog = () => {
        const onCancel = () => {
            setModal(null);
        };

        const onSuccess = () => {
            setModal(null);
        };

        setModal(<ChangePasswordDialog onCancel={onCancel} onSuccess={onSuccess} isAdmin={true} id={data.id}/>);
    };

    const save = () => {
        if (!data.first_name || data.first_name?.length < 1) return setError('Не указано имя');
        if (!data.last_name || data.last_name?.length < 1) return setError('Не указана фамилия');
        if (!data.phone || data.phone?.length < 3) return setError('Не указан номер телефона');

        const parsedMail = (data.email.match(/.+@.+\..+/gmi) || [])[0] || null;
        if (!parsedMail) return setError('Некорректный адрес Email');

        setError(null);
        editUser(data.id, data)
            .then(r => {
                console.log('[save]', r);
                setData(r.data);
                setDisplaySaveButton(false);
                dispatch({type: 'OPEN_SNACKBAR', severity: 'success', message: 'Изменения сохранены!'});
            })
            .catch(err => {
                console.error(err);
            });
    };

    const deleteAccount = () => {
        const cancel = () => {
            setModal(null);
        };

        const confirm = () => {
            deleteUser(data.id)
                .then(r => {
                    console.log('[deleteUser]', r);
                    setModal(null);
                    dispatch({
                        type: 'OPEN_SNACKBAR',
                        severity: 'success',
                        message: `Пользователь #${data.id} успешно удалён!`
                    });
                    setTimeout(() => navigate('/' + location.pathname.split('/')[1] || ''), 500);
                })
                .catch(err => {
                    console.error(err);
                    setModal(null);
                });
        };

        setModal(<ConfirmationDialog
            title={'Удаление аккаунта #' + data.id}
            description={'Вы действительно хотите безвозвратно удалить пользователя ' + data.last_name + ' ' + data.first_name + '?'}
            cancel={{title: 'Отменить', color: 'primary', action: cancel}}
            confirm={{title: 'Удалить', color: 'error', action: confirm}}
        />);
    };


    return (
        <Paper elevation={2} sx={{p: 3, mb: 2, mt: 1}}>
            {modal}
            <Typography variant="h6" sx={{mb: 1.5}}>Тип аккаунта</Typography>
            <Select
                id="register-account-type"
                value={data.account_type}
                sx={{width: 250}}
                disabled
            >
                <MenuItem value={1}>Клиент</MenuItem>
                <MenuItem value={5}>Сотрудник</MenuItem>
                <MenuItem value={10}>Администратор</MenuItem>
            </Select>
            <Typography variant="h6" sx={{mb: 1.5, mt: 3}}>Данные пользователя</Typography>
            <Box sx={{
                '& .MuiTextField-root': {
                    mr: 1.5, ml: 0, mt: 1, mb: 1,
                    width: {lg: '25%', md: '40%', xs: '100%'}
                },
                mt: 1,
                mb: 1
            }}>
                <TextField label="Фамилия" variant="outlined" value={data.last_name} required disabled={!isAdmin}
                           onChange={onChange('last_name')}/>
                <TextField label="Имя" variant="outlined" value={data.first_name} required disabled={!isAdmin}
                           onChange={onChange('first_name')}/>
                <TextField label="Отчество" variant="outlined" value={data.patronymic} disabled={!isAdmin}
                           onChange={onChange('patronymic')}/>
            </Box>

            <Box sx={{
                '& .MuiTextField-root': {
                    mr: 1.5, ml: 0, mt: 1, mb: 1,
                    width: {lg: '38%', md: '82%', xs: '100%'}
                },
                mb: 3
            }}>
                <TextField label="Email" variant="outlined" value={data.email} required disabled={!isAdmin}
                           onChange={onChange('email')}/>
                <TextField label="Телефон" variant="outlined" value={data.phone} required disabled={!isAdmin}
                           onChange={onChange('phone')}/>
            </Box>


            {isAdmin && <Box sx={{mb: 1}}>
                <Button sx={{mr: 2, mb: 1}} variant="outlined" onClick={openPasswordDialog}>Изменить пароль</Button>
                <Button sx={{mb: 1}} variant="outlined" color="error" onClick={deleteAccount}>Удалить аккаунт</Button>
            </Box>}

            {isAdmin && displaySaveButton && <Button variant="contained" onClick={save}>Сохранить</Button>}
            {error && <Alert severity="error" sx={{mt: 3, mb: 1, maxWidth: 800}}>{error}</Alert>}
        </Paper>
    );
}