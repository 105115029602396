import { useDispatch } from 'react-redux';
import React, { useEffect, useState } from 'react';
import {
    Alert, Button, CircularProgress, Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    InputLabel, LinearProgress,
    MenuItem,
    Select,
    TextField
} from '@mui/material';
import { format } from 'date-fns';
import LoadingButton from '@mui/lab/LoadingButton';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import ChangePasswordDialog from './ChangePasswordDialog';
import { deleteSchedule, deleteUser, getScheduleByDate } from '../../utils/api';
import FastSchedulingDialog from './FastSchedulingDialog';
import SchedulingDialog from './SchedulingDialog';
import { ConfirmationDialog } from '../ConfirmationDialog';
import AppointmentDatePicker from '../AppointmentDatePicker';

export default function ScheduleMenu({staffId, date, onCancel, onSuccess}) {
    const dispatch = useDispatch();
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
    const [modal, setModal] = useState(null);
    const [data, setData] = useState(null);

    useEffect(() => {
        getScheduleData();
    }, [date]);

    const getScheduleData = () => {
        setLoading(true);
        getScheduleByDate(staffId, format(date, 'yyyy-MM-dd'))
            .then(r => {
                console.log('getScheduleByDate', r.data);
                setData(r.data);
                setLoading(false);
            })
            .catch(err => {
                setLoading(false);
                console.error(err);
            });
    };

    const addScheduleAction = () => {
        const cancel = () => {
            setModal(null);
        };

        const success = () => {
            setModal(null);
            onSuccess(date);
        };

        setModal(<SchedulingDialog staffId={staffId} date={date} onSuccess={success} onCancel={cancel}/>);
    };

    const deleteScheduleAction = () => {
        const prettyDate = format(new Date(date), 'dd.MM.yyyy');
        const cancel = () => {
            setModal(null);
        };

        const confirm = () => {
            deleteSchedule(staffId, format(new Date(date), 'yyyy-MM-dd'))
                .then(r => {
                    console.log('[deleteSchedule]', r);
                    setModal(null);
                    dispatch({
                        type: 'OPEN_SNACKBAR',
                        severity: 'success',
                        message: `Расписание на ${prettyDate} успешно удалено!`
                    });
                    onSuccess(date);
                })
                .catch(err => {
                    console.error(err);
                    setModal(null);
                });
        };

        setModal(<ConfirmationDialog
            title={'Удаление расписания'}
            description={'Вы действительно хотите удалить расписание на ' + prettyDate + '?'}
            cancel={{title: 'Отменить', color: 'primary', action: cancel}}
            confirm={{title: 'Удалить', color: 'error', action: confirm}}
        />);
    };

    return (
        <Dialog open={true} onClose={onCancel} maxWidth="xs" disableEnforceFocus fullWidth>
            {modal}
            <DialogTitle>{format(date, 'dd.MM.yyyy')} {data ? ' – рабочий день' : ' – нерабочий день'}</DialogTitle>
            <DialogContent>
                {loading ? <CircularProgress/>
                    : data ? (
                        <>
                            <Button variant="contained" onClick={addScheduleAction} startIcon={<EditIcon/>}>
                                Редактировать расписание
                            </Button>
                            <Button variant="contained" color="warning" startIcon={<DeleteIcon/>}
                                    onClick={deleteScheduleAction} sx={{mt: 1.3, mb: 1}}>
                                Сделать день нерабочим
                            </Button>
                        </>
                    ) : <>
                        <Button variant="contained" onClick={addScheduleAction} startIcon={<AddIcon/>}>
                            Добавить расписание
                        </Button>
                    </>

                }
                {error && <Alert severity="error" sx={{mt: 3, mb: 1, maxWidth: 800}}>{error}</Alert>}
            </DialogContent>
            <DialogActions>
                <Button onClick={onCancel}>Закрыть</Button>
            </DialogActions>
            {loading && <LinearProgress style={{height: 6}}/>}
        </Dialog>
    );
}