import { Box, Button, CircularProgress, Container } from '@mui/material';
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { getUser } from '../../../utils/api';
import EditAccount from '../../../components/EditAccount';
import AppointmentsGrid from '../../../components/AppointmentsGrid';
import CreateAppointmentDialog from '../../../components/Dialogs/CreateAppointmentDialog';
import { useSelector } from 'react-redux';

export default function ClientPage({match}) {
    const params = useParams();
    const [loading, setLoading] = useState(true);
    const [modal, setModal] = useState(null);
    const [data, setData] = useState({});

    useEffect(() => {
        getUser(params.id)
            .then(r => {
                console.log('[getUser]', r.data);
                setData(r.data);
                setLoading(false);
            })
            .catch(err => {

            });
    }, [params]);

    const createAppointment = () => {
        const onCancel = () => {
            setModal(null);
        };

        const onSuccess = () => {
            setModal(null);
        };

        setModal(<CreateAppointmentDialog onSuccess={onSuccess} onCancel={onCancel}/>);
    };

    return (
        <Container component="main" maxWidth="xl">
            {modal}
            <h1 style={{marginBottom: 20, marginTop: 20}}>Клиент #{params.id}</h1>
            {loading && <CircularProgress/>}
            {data.id && <Box>
                <EditAccount initialData={data}/>
                <AppointmentsGrid clientId={data.id}/>
            </Box>}
        </Container>
    );
}