import React, { useState, useEffect } from 'react';
import {
    Avatar,
    Box,
    CircularProgress,
    Container, Divider,
    IconButton, Link, List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    Typography
} from '@mui/material';
import { appointmentGetStaffServices, getAvailableStaff } from '../../../utils/api';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import PersonIcon from '@mui/icons-material/Person';
import { useNavigate, Link as RouterLink, Navigate } from 'react-router-dom';
import BackLink from '../../../components/BackLink';
import { useDispatch, useSelector } from 'react-redux';

export default function SelectServicePage() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const appointment = useSelector(state => state.appointment);
    const [loading, setLoading] = useState(true);
    const [rows, setRows] = useState([]);

    useEffect(() => {
        getData();
    }, []);

    const getData = async () => {
        setLoading(true);
        appointmentGetStaffServices(appointment.staff.id)
            .then(r => {
                console.log('[appointmentGetStaffServices]', r.data);
                setLoading(false);
                setRows(r.data.rows);
            })
            .catch(err => {
                setLoading(false);
            });
    };

    const onClick = (data) => {
        dispatch({ type: 'APPOINTMENT_SET_SERVICE', payload: data });
        navigate('/new');
    };

    const Content = <>
        <BackLink to="/new"/>
        <Typography variant="h6">Выберите услугу</Typography>
        {loading && <CircularProgress/>}
        {!loading && <List>
            {rows?.map(i => (
                <ListItem
                    key={i.id}
                    disableGutters
                    divider
                    onClick={() => onClick(i)}
                    secondaryAction={
                        <IconButton onClick={() => onClick(i)}>
                            <ArrowForwardIosIcon fontSize="small"/>
                        </IconButton>
                    }
                >
                    <ListItemButton>
                        <Box>
                            <Typography>{i.title}</Typography>
                            <Typography variant="body2" color="gray">{i.duration_minutes} минут</Typography>
                        </Box>
                    </ListItemButton>
                </ListItem>
            ))}
        </List>
        }
    </>;

    return (
        <Container maxWidth="md">
            {!appointment.staff?.id ? <Navigate to="/new"/> : Content}
        </Container>
    );
}