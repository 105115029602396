import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import logger from 'redux-logger';

import { snackbarReducer } from './reducers/snackbar';
import { accountReducer } from './reducers/account';
import { appointmentReducer } from './reducers/appointment';

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
    combineReducers({
        snackbar: snackbarReducer,
        account: accountReducer,
        appointment: appointmentReducer
    }),
    composeEnhancer(applyMiddleware(thunk, logger))
);

export default store;