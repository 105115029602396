import {
    Alert, Box,
    Button, Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle, Divider,
    LinearProgress,
    TextField, Typography
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { LocalizationProvider, PickersDay, StaticDatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { format, parse } from 'date-fns';
import TimeSelector from '../TimeSelector';
import ScheduleDatePicker from '../ScheduleDatePicker';
import { fastScheduling } from '../../utils/api';

export default function FastSchedulingDialog({staffId, onCancel, onSuccess}) {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [selectedDates, setSelectedDates] = useState([]);
    const [timeData, setTimeData] = useState({
        start_time: '03:00:00+00',
        end_time: '12:00:00+00',
        excepts: ['07:00:00+00', '07:30:00+00']
    });

    const save = () => {
        console.log('save', {selectedDates, timeData});

        if (!selectedDates || selectedDates.length === 0) return setError('Выберите минимум один день, для которого необходимо установить данное расписание');

        setLoading(true);
        setError(null);
        fastScheduling(staffId, selectedDates, timeData.start_time, timeData.end_time, timeData.excepts)
            .then(r => {
                console.log('[fastScheduling]', r.data);
                dispatch({type: 'OPEN_SNACKBAR', severity: 'success', message: 'График работы успешно установлен!'});
                onSuccess();
            })
            .catch(err => {
                console.error(err);
                setLoading(false);
                setError(err.response.data.message || 'Произошла ошибка ' + err.response.status);
            });
    };


    return (
        <Dialog open={true} onClose={onCancel} maxWidth="sm" disableEnforceFocus fullWidth scroll="body">
            <DialogTitle>Добавление рабочих дней</DialogTitle>
            <DialogContent>
                <Divider sx={{mt: 0, mb: 1.5}}/>
                <Typography variant="h6">1. Выберите рабочие дни</Typography>
                <Typography variant="body2">К этим дням применится ваше расписание</Typography>
                <ScheduleDatePicker staffId={staffId} onChange={(d) => setSelectedDates(d)}/>
                <Alert severity="info" sx={{mt: 1}}>
                    <b>Будут добавлены рабочие дни: </b>
                    {selectedDates.map((date) => format(new Date(date), 'dd.MM')).join(', ')}
                </Alert>

                <Divider sx={{mt: 3, mb: 1.5}}/>
                <Typography variant="h6">2. Укажите рабочее время</Typography>
                <Typography variant="body2">В это время будет доступна запись</Typography>
                <TimeSelector initialData={timeData} onChange={(d) => setTimeData(d)}/>
                {error && <Alert severity="error" sx={{mt: 3, mb: 1, maxWidth: 800}}>{error}</Alert>}
            </DialogContent>
            <DialogActions>
                <Button onClick={onCancel}>Отменить</Button>
                <LoadingButton onClick={save} color="primary" variant="contained" loading={loading}>
                    Добавить
                </LoadingButton>
            </DialogActions>
            {loading && <LinearProgress style={{height: 6}}/>}
        </Dialog>
    );
}