import React, { useEffect, useState } from 'react';

import {
    AppBar,
    Box, Avatar,
    Divider,
    Drawer, IconButton,
    List,
    ListItem,
    ListItemIcon,
    ListItemText, Menu, MenuItem,
    Toolbar,
    Typography
} from '@mui/material';

import MenuIcon from '@mui/icons-material/Menu';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

import WorkIcon from '@mui/icons-material/Work';
import PeopleIcon from '@mui/icons-material/People';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';


import { useDispatch, useSelector } from 'react-redux';
import { getMyAccountInfo } from '../../utils/api';
import { useNavigate, useLocation } from 'react-router-dom';

const drawerWidth = 240;

function Navigation(props) {
    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();
    const accountInfo = useSelector(state => state.account);
    const [mobileOpen, setMobileOpen] = useState(false);
    const [accountOpen, setAccountOpen] = React.useState(false);
    const [activePage, setActivePage] = React.useState('');
    const [activeSubPage, setActiveSubPage] = React.useState('');
    const [anchorEl, setAnchorEl] = React.useState(null);
    const isMenuOpen = Boolean(anchorEl);

    useEffect(() => {
        getMyAccountInfo()
            .then(r => dispatch({type: 'SET_ACCOUNT_DATA', payload: r.data}));
    }, []);

    useEffect(() => {
        const page = location.pathname.split('/')[1];
        const subPage = location.pathname.split('/')[2];

        setActivePage(page);
        setActiveSubPage(subPage);
    }, [location]);

    const exit = () => {
        setAnchorEl(null);
        localStorage.removeItem('refresh_token');
        localStorage.removeItem('access_token');
        localStorage.removeItem('user');
        window.location.reload();
    };

    const drawer = (
        <div>
            <Toolbar/>
            <Divider/>
            {accountInfo.account_type === 10 ? <List>
                <ListItem button selected={activePage === 'staff'} onClick={() => navigate('/staff')}>
                    <ListItemIcon><WorkIcon/></ListItemIcon>
                    <ListItemText primary={'Сотрудники'}/>
                </ListItem>
                <ListItem button selected={activePage === 'admins'} onClick={() => navigate('/admins')}>
                    <ListItemIcon><AdminPanelSettingsIcon/></ListItemIcon>
                    <ListItemText primary={'Администраторы'}/>
                </ListItem>
            </List> : <List>
                <ListItem button selected={activePage === 'services'} onClick={() => navigate('/services')}>
                    <ListItemIcon><DirectionsCarIcon/></ListItemIcon>
                    <ListItemText primary={'Мои услуги'}/>
                </ListItem>
                <ListItem button selected={activePage === 'schedule'} onClick={() => navigate('/schedule')}>
                    <ListItemIcon><CalendarMonthIcon/></ListItemIcon>
                    <ListItemText primary={'График работы'}/>
                </ListItem>
            </List>

            }
            <Divider/>
            <List>
                <ListItem button selected={activePage === 'clients'} onClick={() => navigate('/clients')}>
                    <ListItemIcon><PeopleIcon/></ListItemIcon>
                    <ListItemText primary={'Клиенты'}/>
                </ListItem>
                <ListItem button selected={activePage === 'appointments'} onClick={() => navigate('/appointments')}>
                    <ListItemIcon><CalendarMonthIcon/></ListItemIcon>
                    <ListItemText primary={'Записи'}/>
                </ListItem>
            </List>
        </div>
    );

    const container = window !== undefined ? () => window.document.body : undefined;
    const handleDrawerToggle = () => setMobileOpen(!mobileOpen);

    const handleProfileMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleAccountClose = () => {
        setAnchorEl(null);
    };

    const renderMenu = (
        <Menu
            anchorEl={anchorEl}
            anchorOrigin={{vertical: 'top', horizontal: 'right'}}
            id={'account-menu'}
            keepMounted
            transformOrigin={{vertical: 'top', horizontal: 'right'}}
            style={{zIndex: 10000, top: 30}}
            open={isMenuOpen}
            onClose={handleAccountClose}
            PaperProps={{
                elevation: 0,
                sx: {
                    overflow: 'visible',
                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                    mt: 1.5,
                    '& .MuiAvatar-root': {
                        width: 32,
                        height: 32,
                        ml: -0.5,
                        mr: 1
                    },
                    '&:before': {
                        content: '""',
                        display: 'block',
                        position: 'absolute',
                        top: 0,
                        right: 14,
                        width: 10,
                        height: 10,
                        bgcolor: 'background.paper',
                        transform: 'translateY(-50%) rotate(45deg)',
                        zIndex: 0
                    }
                }
            }}
        >
            <MenuItem><Avatar/>{accountInfo.first_name}</MenuItem>
            <Divider/>
            <MenuItem onClick={() => {
                navigate('/account');
                handleAccountClose();
            }}>Мой аккаунт</MenuItem>
            <MenuItem onClick={() => exit()}>Выход</MenuItem>
        </Menu>
    );

    return (
        <Box sx={{display: 'flex'}}>
            <AppBar position="fixed" sx={{zIndex: (theme) => theme.zIndex.drawer + 1}}>
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        sx={{mr: 2, display: {sm: 'none'}}}
                    >
                        <MenuIcon/>
                    </IconButton>
                    <Typography variant="h6" noWrap component="div" sx={{flexGrow: 1, cursor: 'pointer'}}
                                onClick={() => navigate('/')}>
                        RuliKZ CRM
                    </Typography>
                    <IconButton color="inherit" onClick={handleProfileMenuOpen}>
                        <AccountCircleIcon/>
                    </IconButton>
                </Toolbar>
                {anchorEl && renderMenu}
            </AppBar>
            <Box
                component="nav"
                sx={{width: {sm: drawerWidth}, flexShrink: {sm: 0}}}
                aria-label="mailbox folders"
            >
                {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
                <Drawer
                    container={container}
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true // Better open performance on mobile.
                    }}
                    sx={{
                        display: {xs: 'block', sm: 'none'},
                        '& .MuiDrawer-paper': {boxSizing: 'border-box', width: drawerWidth}
                    }}
                >
                    {drawer}
                </Drawer>
                <Drawer
                    variant="permanent"
                    sx={{
                        display: {xs: 'none', sm: 'block'},
                        '& .MuiDrawer-paper': {boxSizing: 'border-box', width: drawerWidth}
                    }}
                    open
                >
                    {drawer}
                </Drawer>
            </Box>
            <Box sx={{mt: {xs: 9, md: 10}, ml: {xs: 2, md: 4}, mr: {xs: 2, md: 4}, mb: 5, width: '100%'}}>
                {props.children}
            </Box>
        </Box>
    );
}

export default Navigation;