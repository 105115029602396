import axios from 'axios';

export async function auth(username, password) {
    return await axios.post('oauth/authorize',
        JSON.stringify({ username, password })
    );
}

export async function register(email, first_name, last_name, phone, password) {
    return await axios.post('account/register',
        JSON.stringify({ email, first_name, last_name, phone, password })
    );
}

export async function createUser(account_type, email, first_name, last_name, phone, password) {
    return await axios.post('users/create',
        JSON.stringify({ account_type, email, first_name, last_name, phone, password })
    );
}

export async function refresh() {
    try {
        const { data } = await oauthRefresh();
        localStorage.setItem('access_token', data.access_token);
        localStorage.setItem('refresh_token', data.refresh_token);
        axios.defaults.headers.Authorization = 'Bearer ' + data.access_token;
        console.info('[OAuth] Token has been refreshed');
        if (data.need_update) window.location.pathname = '/login';
        return data.access_token;
    } catch (e) {
        localStorage.removeItem('access_token');
        localStorage.removeItem('refresh_token');
        window.location.reload();
        console.warn('[OAuth] Session Expired!');
    }
}

export async function oauthRefresh() {
    return await axios.post('oauth/refresh',
        JSON.stringify({ refresh_token: localStorage.getItem('refresh_token') })
    );
}

export async function getMyAccountInfo() {
    return await axios.get('account/info');
}

export async function getMyAccountAppointments() {
    return await axios.get('account/appointments');
}

export async function editMyProfile({ first_name, last_name, patronymic, phone, email }) {
    return await axios.patch('account/edit', { first_name, last_name, patronymic, phone, email });
}

export async function changeMyPassword(oldPassword, newPassword) {
    return await axios.put('account/password', { old_password: oldPassword, new_password: newPassword });
}

export async function searchUsers(params) {
    return await axios.get('users/search', { params });
}

export async function getUser(id) {
    return await axios.get('users/' + id);
}

export async function editUser(id, { first_name, last_name, patronymic, phone, email, account_type }) {
    return await axios.patch('users/' + id, { first_name, last_name, patronymic, phone, email, account_type });
}

export async function deleteUser(id) {
    return await axios.delete('users/' + id);
}

export async function changeUserPassword(id, newPassword) {
    return await axios.put('users/' + id + '/password', { new_password: newPassword });
}

export async function getSchedule(staffId, month) {
    return await axios.get(`schedule/staff/${staffId}`, { params: { month } });
}

export async function getScheduleByDate(staffId, date) {
    return await axios.get(`schedule/staff/${staffId}/${date}`);
}

export async function createSchedule(staffId, date, start_time, end_time, excepts) {
    return await axios.post(`schedule/staff/${staffId}/${date}`, { start_time, end_time, excepts });
}

export async function editSchedule(staffId, date, start_time, end_time, excepts) {
    return await axios.patch(`schedule/staff/${staffId}/${date}`, { start_time, end_time, excepts });
}

export async function deleteSchedule(staffId, date) {
    return await axios.delete(`schedule/staff/${staffId}/${date}`);
}

export async function fastScheduling(staffId, dates, startTime, endTime, excepts) {
    return await axios.post(
        `schedule/staff/${staffId}/fast_scheduling`,
        { dates, start_time: startTime, end_time: endTime, excepts }
    );
}

export async function getStaffServices(staffId) {
    return await axios.get('/services/list', { params: { staff_id: staffId } });
}

export async function getService(serviceId) {
    return await axios.get('/services/' + serviceId);
}

export async function getServicesList(staff_id) {
    return await axios.get('/services/list', { params: { staff_id } });
}

export async function createService(staffId, { title, description, duration_minutes }) {
    return await axios.post('/services', { title, description, duration_minutes }, { params: { staff_id: staffId } });
}

export async function editService(serviceId, { title, description, duration_minutes }) {
    return await axios.patch('/services/' + serviceId, { title, description, duration_minutes });
}

export async function deleteService(serviceId) {
    return await axios.delete('/services/' + serviceId);
}

export async function appointmentGetMonthData(staffId, serviceId, month, appointmentId = 0) {
    return await axios.get(`/appointments/staff/${staffId}/${serviceId}/${month}`, { params: { appointment_id: appointmentId } });
}

export async function createAppointment(client_id, staff_id, service_id, date, time, comment) {
    return await axios.post(`/appointments/staff/${staff_id}/${service_id}/${date}`, { client_id, time, comment });
}

export async function searchAppointments({ clientId, staffId, preview, month, q, status }) {
    return await axios.get('/appointments/search', {
        params: {
            client_id: clientId,
            staff_id: staffId,
            preview,
            month,
            q,
            status
        }
    });
}

export async function getAvailableStaff() {
    return await axios.get('/appointments/staff');
}

export async function appointmentGetStaffServices(staffId) {
    return await axios.get(`/appointments/staff/${staffId}/services`);
}

export async function getAppointment(appointmentId) {
    return await axios.get('/appointments/' + appointmentId);
}

export async function deleteAppointment(appointmentId) {
    return await axios.delete('/appointments/' + appointmentId);
}

export async function editAppointment(appointmentId, {
    clientId,
    staffId,
    serviceId,
    date,
    time,
    status,
    description
}) {
    return await axios.patch(
        '/appointments/' + appointmentId,
        { client_id: clientId, staff_id: staffId, service_id: serviceId, date, time, status, description }
    );
}

export async function getStaffStats(staffId) {
    return await axios.get('/appointments/staff/' + staffId + '/stats');
}