import { useEffect, useState } from 'react';
import { Box, Button, Card, CardContent, Chip, CircularProgress, Container, Typography } from '@mui/material';
import { getMyAccountAppointments } from '../../utils/api';
import { format, formatInTimeZone } from 'date-fns-tz';
import ru from 'date-fns/locale/ru';
import { parse } from 'date-fns';
import StaffStats from '../../components/StaffStats';
import { useSelector } from 'react-redux';

export default function MyAppointments() {
    const [rows, setRows] = useState(null);
    const [loading, setLoading] = useState(true);
    const accountInfo = useSelector(state => state.account);

    const chips = {
        1: <Chip label="Новая запись" color="primary" sx={{ mt: 0.5 }}/>,
        2: <Chip label="Запись подтверждена" color="success" sx={{ mt: 0.5 }}/>,
        3: <Chip label="Запись отменена" color="error" sx={{ mt: 0.5 }}/>
    };

    useEffect(() => {
        getData();
    }, []);

    const getData = () => {
        setLoading(true);
        getMyAccountAppointments()
            .then(r => {
                console.log('Rows', r.data.rows);
                setLoading(false);
                setRows(r.data.rows);
            })
            .catch(err => {
                console.error(err);
                setLoading(false);
            });
    };

    return (
        <Container maxWidth="md">
            <h1>Мои записи</h1>
            {loading && <CircularProgress/>}
            {!loading && <Box>
                {!rows?.length && <Typography>У вас нет записей</Typography>}
                {rows.map(item => <Card sx={{ minWidth: 275, mb: 3 }} elevation={2}>
                    <CardContent>
                        <Box sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            flexWrap: 'wrap',
                            mb: 1.5
                        }}>
                            <Typography variant="h5" component="div" sx={{ mt: 0.5 }}>
                                #{item.id} {item.service.title}
                            </Typography>
                            {chips[item.status]}
                        </Box>
                        <Typography sx={{ fontSize: 18 }} variant="h6" gutterBottom>
                            {format(new Date(item.date), 'dd MMMM yyyy', { locale: ru })}
                        </Typography>
                        <Typography sx={{ fontSize: 16 }} gutterBottom>
                            {formatInTimeZone(parse(item.start_time, 'HH:mm:ssx', new Date()), 'Asia/Yekaterinburg', 'HH:mm ')}
                            – {formatInTimeZone(parse(item.end_time, 'HH:mm:ssx', new Date()), 'Asia/Yekaterinburg', 'HH:mm')}
                        </Typography>
                        <Typography sx={{ fontWeight: 500, mt: 2, mb: 0.5 }}>Комментарий:</Typography>
                        <Typography>{item.comment}</Typography>
                        <Typography sx={{ fontWeight: 500, mt: 2, mb: 0.5 }}>Инструктор:</Typography>
                        <Typography>{item.staff.last_name} {item.staff.first_name} {item.staff.patronymic}</Typography>
                        <Typography color="gray">Телефон: {item.staff.phone}</Typography>
                    </CardContent>
                </Card>)}
            </Box>}
        </Container>
    );
}
