import {
    Box,
    Button, CircularProgress,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { getStaffStats } from '../../utils/api';

export default function StaffStats({ staffId }) {
    const [data, setData] = useState(null);

    useEffect(() => {
        getStaffStats(staffId)
            .then(r => {
                const d = r.data?.data;
                console.log('staffStats', d);
                setData(d);
            });
    }, [staffId]);

    return (
        <Box sx={{ mt: 1, mb: 2 }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexWrap: 'wrap' }}>
                <h1 style={{ marginBottom: 10, marginTop: 20 }}>Статистика</h1>
            </Box>
            <Typography sx={{ mt: 0, mb: 2 }}>Отчёт о проделанной работе</Typography>
            {!data?.stats_2 && !data?.stats_3 && <CircularProgress/>}
            {data?.stats_2 && data?.stats_3 && <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Тип записей</TableCell>
                            <TableCell align="right">Сегодня</TableCell>
                            <TableCell align="right">Вчера</TableCell>
                            <TableCell align="right">7 дней</TableCell>
                            <TableCell align="right">30 дней</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell>Подтверждённые записи</TableCell>
                            <TableCell align="right">{data.stats_2.today}</TableCell>
                            <TableCell align="right">{data.stats_2.yesterday}</TableCell>
                            <TableCell align="right">{data.stats_2.d7}</TableCell>
                            <TableCell align="right">{data.stats_2.d30}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Отменённые записи</TableCell>
                            <TableCell align="right">{data.stats_3.today}</TableCell>
                            <TableCell align="right">{data.stats_3.yesterday}</TableCell>
                            <TableCell align="right">{data.stats_3.d7}</TableCell>
                            <TableCell align="right">{data.stats_3.d30}</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>}
        </Box>
    );
}