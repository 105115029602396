import React, { useEffect, useState } from 'react';
import { createSchedule, editSchedule, fastScheduling, getScheduleByDate } from '../../utils/api';
import { format } from 'date-fns';
import { useDispatch } from 'react-redux';
import {
    Alert,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    LinearProgress, Typography
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import TimeSelector from '../TimeSelector';
import LoadingButton from '@mui/lab/LoadingButton';

export default function SchedulingDialog({staffId, date, onCancel, onSuccess}) {
    const dispatch = useDispatch();
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
    const [modal, setModal] = useState(null);
    const [isEditMode, setIsEditMode] = useState(null);
    const [timeData, setTimeData] = useState({
        start_time: '03:00:00+00',
        end_time: '12:00:00+00',
        excepts: ['07:00:00+00', '07:30:00+00']
    });

    useEffect(() => {
        getScheduleData();
    }, [date]);

    const getScheduleData = () => {
        setLoading(true);
        getScheduleByDate(staffId, format(new Date(date), 'yyyy-MM-dd'))
            .then(r => {
                console.log('getScheduleByDate', r.data);
                setIsEditMode(true);
                setTimeData({start_time: r.data.start_time, end_time: r.data.end_time, excepts: r.data.excepts});
                setLoading(false);
            })
            .catch(err => {
                setLoading(false);
                console.error(err);
            });
    };

    const save = () => {
        console.log('save', timeData);

        setLoading(true);
        setError(null);

        if (isEditMode) {
            editSchedule(staffId, format(new Date(date), 'yyyy-MM-dd'), timeData.start_time, timeData.end_time, timeData.excepts)
                .then(r => {
                    console.log('[editSchedule]', r.data);
                    dispatch({type: 'OPEN_SNACKBAR', severity: 'success', message: 'График работы успешно изменён!'});
                    setLoading(false);
                    onSuccess();
                })
                .catch(err => {
                    console.error(err);
                    setLoading(false);
                    setError(err.response.data.message || 'Произошла ошибка ' + err.response.status);
                });
        } else {
            createSchedule(staffId, format(new Date(date), 'yyyy-MM-dd'), timeData.start_time, timeData.end_time, timeData.excepts)
                .then(r => {
                    console.log('[createSchedule]', r.data);
                    dispatch({
                        type: 'OPEN_SNACKBAR',
                        severity: 'success',
                        message: 'График работы успешно установлен!'
                    });
                    setLoading(false);
                    onSuccess();
                })
                .catch(err => {
                    console.error(err);
                    setLoading(false);
                    setError(err.response.data.message || 'Произошла ошибка ' + err.response.status);
                });
        }
    };

    return (
        <Dialog open={true} onClose={onCancel} maxWidth="sm" disableEnforceFocus fullWidth>
            <DialogTitle>{format(date, 'dd.MM.yyyy')} - {isEditMode ? 'Редактирование расписания' : 'Добавление расписания'}</DialogTitle>
            <DialogContent>
                {loading && <CircularProgress/>}
                {!loading && <>
                    <Typography variant="h6">Укажите рабочее время</Typography>
                    <Typography variant="body2">В это время будет доступна запись</Typography>
                    <TimeSelector initialData={timeData} onChange={(d) => setTimeData(d)}/>
                </>}
                {error && <Alert severity="error" sx={{mt: 3, mb: 1, maxWidth: 800}}>{error}</Alert>}
            </DialogContent>
            <DialogActions>
                <Button onClick={onCancel}>Закрыть</Button>
                <LoadingButton onClick={save} color="primary" variant="contained" loading={loading}>
                    {isEditMode ? 'Сохранить' : 'Добавить'}
                </LoadingButton>
            </DialogActions>
            {loading && <LinearProgress style={{height: 6}}/>}
        </Dialog>
    );
}