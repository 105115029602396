import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    LinearProgress,
    TextField,
    InputLabel,
    Button,
    Alert, MenuItem, Select, Typography
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import React, { useState } from 'react';
import { changeMyPassword, createUser, register } from '../../utils/api';
import { useDispatch } from 'react-redux';

export default function CreateAccountDialog({onCancel, onSuccess, initialAccountType}) {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [email, setEmail] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [patronymic, setPatronymic] = useState('');
    const [phone, setPhone] = useState('');
    const [accountType, setAccountType] = useState(initialAccountType);
    const [password, setPassword] = useState('');
    const [password2, setPassword2] = useState('');

    const accountTypes = {
        1: 'Клиент',
        5: 'Сотрудник',
        10: 'Администратор'
    };

    const save = () => {
        const parsedMail = (email.match(/.+@.+\..+/gmi) || [])[0] || null;
        if (!parsedMail) return setError('Некорректный Email адрес');

        if (!phone || phone?.length < 0) return setError('Не указан номер телефона');
        if (!firstName || firstName?.length < 0) return setError('Введите имя');
        if (!lastName || lastName?.length < 0) return setError('Введите фамилию');
        if (!password || password?.length < 8) return setError('Минимальная длина пароля: 8 символов');
        if (password !== password2) return setError('Пароли не совпадают');

        setError(null);
        setLoading(true);

        createUser(accountType, parsedMail, firstName, lastName, phone, password)
            .then(r => {
                console.log('[createUser]', r.data);
                setLoading(false);
                dispatch({
                    type: 'OPEN_SNACKBAR',
                    severity: 'success',
                    message: 'Зарегистрирован пользователь с ID ' + r.data.id
                });
                onSuccess(r.data.id);
            })
            .catch(err => {
                console.error(err);
                setLoading(false);
                setError(err.response.data.message || 'Произошла ошибка ' + err.response.status);
            });
    };

    return (
        <Dialog open={true} onClose={onCancel} maxWidth="xs" disableEnforceFocus fullWidth>
            <DialogTitle>Регистрация пользователя</DialogTitle>
            <DialogContent>
                <Typography variant="h6" sx={{mb: 1}}>Тип аккаунта</Typography>
                <Select
                    id="register-account-type"
                    value={accountType}
                    fullWidth
                    onChange={(e) => setAccountType(e.target.value)}
                >
                    <MenuItem value={1}>Клиент</MenuItem>
                    <MenuItem value={5}>Сотрудник</MenuItem>
                    <MenuItem value={10}>Администратор</MenuItem>
                </Select>

                <Typography variant="h6" sx={{mt: 2.5, mb: 0.5}}>Данные пользователя</Typography>
                <TextField
                    margin="dense"
                    fullWidth
                    label="Email адрес"
                    value={email}
                    onChange={(e) => setEmail(e.currentTarget.value)}
                    type="email"
                    autoComplete="email"
                    autoFocus
                />
                <TextField
                    margin="dense"
                    fullWidth
                    label="Телефон"
                    type="phone"
                    value={phone}
                    onChange={(e) => setPhone(e.currentTarget.value)}
                />
                <TextField
                    margin="dense"
                    fullWidth
                    sx={{mt: 3}}
                    label="Фамилия"
                    value={lastName}
                    onChange={(e) => setLastName(e.currentTarget.value)}
                />
                <TextField
                    margin="dense"
                    fullWidth
                    label="Имя"
                    value={firstName}
                    onChange={(e) => setFirstName(e.currentTarget.value)}
                />

                <Typography variant="h6" sx={{mt: 2.5, mb: 0.5}}>Пароль</Typography>
                <TextField
                    margin="dense"
                    fullWidth
                    value={password}
                    onChange={(e) => setPassword(e.currentTarget.value)}
                    label="Пароль"
                    type="password"
                />
                <TextField
                    margin="dense"
                    fullWidth
                    value={password2}
                    onChange={(e) => setPassword2(e.currentTarget.value)}
                    label="Повтор пароля"
                    type="password"
                />

            </DialogContent>
            {error && <Alert severity="error" sx={{mt: 3, mb: 1, maxWidth: 800}}>{error}</Alert>}
            <DialogActions>
                <Button onClick={onCancel}>Отменить</Button>
                <LoadingButton onClick={save} color="primary" variant="contained" loading={loading}>
                    Зарегистрировать
                </LoadingButton>
            </DialogActions>
            {loading && <LinearProgress style={{height: 6}}/>}
        </Dialog>
    );
}