import React, { useState } from 'react';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle, LinearProgress
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';

export function ConfirmationDialog({title, description, cancel, confirm}) {
    const [loading, setLoading] = useState(false);

    const Progress = () => loading && <LinearProgress style={{height: 6}}/>;

    const withLoading = (cb) => {
        setLoading(true);
        cb();
        // setLoading(false);
    };

    return (
        <Dialog
            open={true}
            keepMounted
            onClose={cancel.action}
            aria-describedby="alert-dialog-slide-description"
            maxWidth="xs"
        >
            <Progress/>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-slide-description">
                    {description}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <LoadingButton onClick={() => withLoading(cancel.action)} color={cancel.color}
                               loading={loading}>{cancel.title}</LoadingButton>
                <LoadingButton onClick={() => withLoading(confirm.action)} color={confirm.color} style={confirm.style}
                               variant="contained" loading={loading}>{confirm.title}</LoadingButton>
            </DialogActions>
        </Dialog>
    );
}