import './App.css';
import { BrowserRouter, Link, Route, Routes } from 'react-router-dom';
import { Login, Register } from './pages/Auth';
import PrivateRoute from './components/PrivateRoute';
import React, { useEffect } from 'react';
import axios from 'axios';
import AdminArea from './components/AdminArea';
import Snackbar from './components/Snackbar';
import { useDispatch } from 'react-redux';
import { getMyAccountInfo, refresh } from './utils/api';

function App() {
    const dispatch = useDispatch();

    // axios.defaults.baseURL = 'http://94.41.65.26:11850';
    axios.defaults.baseURL = 'https://api.ruli.kz';
    axios.defaults.headers = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.access_token
    };

    axios.interceptors.response.use(
        function (response) {
            return response;
        },
        async function (error) {
            const status = error.response ? error.response.status : null;
            const message = error.response?.data?.message || error.response?.statusText || 'Неизвестная ошибка';

            const errorMessage = error.response?.data?.error;
            if (status === 403 && errorMessage !== 'incorrect_credentials' && errorMessage !== 'not_enough_scopes') {
                const accessToken = await refresh(error);
                error.response.config.headers['Authorization'] = 'Bearer ' + accessToken;
                return axios(error.response.config);
            } else if (status === 401 && errorMessage !== 'incorrect_credentials') {
                localStorage.removeItem('access_token');
                localStorage.removeItem('refresh_token');
                window.location.reload();
                console.log('Session Expired!');
                return;
            } else if (errorMessage !== 'schedule_not_found') {
                dispatch({
                    type: 'OPEN_SNACKBAR', severity: 'error',
                    message: <><b>Ошибка {status}</b><br/>{message}</>
                });
            }

            return Promise.reject(error);
        }
    );

    return (
        <BrowserRouter>
            <Snackbar/>
            <Routes>
                <Route exact path="/login" element={<Login/>}/>
                <Route exact path="/register" element={<Register/>}/>
                <Route exact path={'/'} element={<PrivateRoute/>}>
                    <Route exact path="/" element={<AdminArea/>}/>
                    <Route exact path="/*" element={<AdminArea/>}/>
                </Route>
            </Routes>
        </BrowserRouter>
    );
}

export default App;
