import React, { Children, useCallback, useEffect, useState } from 'react';
import { Calendar, dateFnsLocalizer } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import format from 'date-fns/format';
import parse from 'date-fns/parse';
import startOfWeek from 'date-fns/startOfWeek';
import getDay from 'date-fns/getDay';
import ru from 'date-fns/locale/ru';
import { Container } from '@mui/material';
import { getSchedule, searchAppointments } from '../../utils/api';
import ScheduleMenu from '../Dialogs/ScheduleMenu';
import EditAppointmentDialog from '../Dialogs/EditAppointmentDialog';

const locales = {
    'ru': ru
};

const localizer = dateFnsLocalizer({
    format,
    parse,
    startOfWeek,
    getDay,
    locales
});

const colorsByStatus = {
    1: '#2770c0',
    2: '#11790d',
    3: '#dc6a6a'
};

export default function Schedule({ staffId, initialDate }) {
    const [view, setView] = useState('week');
    const [date, setDate] = useState(initialDate);
    const [schedule, setSchedule] = useState({});
    const [modal, setModal] = useState(null);
    const [loading, setLoading] = useState(true);
    const [events, setEvents] = useState([]);

    useEffect(() => {
        if (!date) {
            setDate(new Date());
            return;
        }

        setLoading(true);

        updateScheduleData()
            .then((schedule) => {
                console.log(schedule);
                setSchedule(schedule);
                setLoading(false);
            });
    }, [date, initialDate]);

    let formats = {
        // dateFormat: 'dd',
        //
        // dayFormat: (date, culture, localizer) => {
        //     console.log(555, culture);
        //     localizer.format(date, 'dd', culture);
        // }


        // dayRangeHeaderFormat: ({start, end}, culture, localizer) =>
        //     localizer.format(start, {date: 'short'}, culture) + ' — ' +
        //     localizer.format(end, {date: 'short'}, culture)
    };

    const updateScheduleData = () => {
        const d = format(new Date(date), 'yyyy-MM-dd');
        return new Promise((resolve, reject) => {
            getSchedule(staffId, d)
                .then(r => {
                    console.log('getSchedule', r.data);
                    const schedule = Object.keys(r.data.schedule).reduce((previousValue, currentValue, currentIndex) => {
                        const x = r.data.schedule[currentValue];
                        if (!x) return { ...previousValue, [currentValue]: null };
                        const start_time = parse(x.start_time, 'HH:mm:ssx', new Date(currentValue));
                        const end_time = parse(x.end_time, 'HH:mm:ssx', new Date(currentValue));
                        const excepts = x.excepts.map(i => parse(i, 'HH:mm:ssx', new Date(currentValue)));
                        return { ...previousValue, [currentValue]: { start_time, end_time, excepts } };
                    }, {});
                    return resolve(schedule);
                })
                .catch(err => {
                    console.error(err);
                    setLoading(false);
                });

            searchAppointments({ staffId, preview: 1, month: d })
                .then(r => {
                    console.log('searchAppointments', r.data);
                    setEvents(r.data.rows.map(i => {
                        return { ...i, start: new Date(i.start), end: new Date(i.end) };
                    }));
                });
        });
    };

    const openScheduleMenu = (date) => {
        const onCancel = () => {
            setModal(null);
        };
        const onSuccess = (d) => {
            setModal(null);
            setDate(new Date(d));
        };
        setModal(<ScheduleMenu staffId={staffId} date={date} onSuccess={onSuccess} onCancel={onCancel}/>);
    };

    const ColoredDateCellWrapper = ({ children, value }) => {
        const d = format(value, 'yyyy-MM-dd');
        const isScheduled = schedule[d];

        return React.cloneElement(Children.only(children), {
            style: {
                ...children.style,
                backgroundColor: isScheduled ? 'lightgreen' : '#bbbbbb',
                cursor: 'pointer'
            },
            onClick: () => {
                console.log('123');
            }
        });
    };

    const timeSlotWrapper = ({ children, resource, value }) => {
        if (resource === undefined) {
            return children;
        }

        const d = format(value, 'yyyy-MM-dd');
        // console.log(d);
        const daySchedule = schedule[d];
        // console.log(schedule);
        if (!daySchedule) return React.cloneElement(Children.only(children), {
            style: {
                ...children.style,
                backgroundColor: '#bbbbbb',
                border: 'none'
            }
        });

        const notWorkTime = value < daySchedule.start_time || value > daySchedule.end_time ||
            daySchedule.excepts.filter(i => localizer.eq(i, value) || (value > i && localizer.diff(i, value) <= 900000))[0];

        // const t = format(value, 'hh:mm:ssx');
        // console.log(value);
        return React.cloneElement(Children.only(children), {
            style: {
                ...children.style,
                backgroundColor: notWorkTime ? '#bbbbbb' : 'lightgreen', border: 'none'
            }
        });
    };

    const eventRenderProps = useCallback((event, start, end, isSelected) => {
        return {
            style: {
                backgroundColor: colorsByStatus[event.status || 1]
            }
        };
    }, []);

    const editAppointment = (appointmentId) => {
        const onCancel = () => {
            setModal(null);
        };

        const onSuccess = (date) => {
            setModal(null);
            setDate(new Date(date));
        };

        setModal(<EditAppointmentDialog
            onSuccess={onSuccess} onCancel={onCancel}
            appointmentId={appointmentId}
        />);
    };


    const onViewChange = (newView) => {
        console.log('onViewChange', newView);
        setView(newView);
    };

    const onDateChange = (newDate) => {
        console.log('onDateChange', newDate);
        setDate(newDate);
    };

    const handleSlotSelection = ({ action, start, end }) => {
        console.log(action, start, end);
        openScheduleMenu(start);
    };

    const handleEventSelection = (data) => {
        console.log(data);
        editAppointment(data.id);
    };


    return (
        <Container>
            {modal}
            <Calendar
                date={date}
                view={view}
                events={events}
                views={['month', 'week', 'day']}
                onView={onViewChange}
                localizer={localizer}
                formats={formats}
                culture="ru"
                // events={myEventsList}
                startAccessor="start"
                endAccessor="end"
                style={{ height: '100%', minHeight: '70vh' }}
                step={15}
                timeslots={1}
                min={new Date(1972, 0, 0, 5, 0, 0, 0)}
                eventPropGetter={eventRenderProps}
                components={{
                    dateCellWrapper: ColoredDateCellWrapper,
                    timeSlotWrapper
                }}
                selectable={true}
                onNavigate={onDateChange}
                onSelectSlot={handleSlotSelection}
                onSelectEvent={handleEventSelection}
                messages={{
                    date: 'Дата',
                    time: 'Время',
                    event: 'Запись',
                    allDay: 'Весь день',
                    week: 'Неделя',
                    work_week: 'Рабочая неделя',
                    day: 'День',
                    month: 'Месяц',
                    previous: 'Назад',
                    next: 'Вперед',
                    yesterday: 'Вчера',
                    tomorrow: 'Завтра',
                    today: 'Сегодня',
                    agenda: 'Agenda',
                    noEventsInRange: 'Нет записей в этом диапазоне.',
                    showMore: total => `+${total} ещё`
                }}
            />
        </Container>
    );
}