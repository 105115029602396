import { Alert, Avatar, Box, Button, Container, Link, TextField, Typography } from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import React, { useEffect, useState } from 'react';
import { auth } from '../../../utils/api';
import { Navigate, useNavigate } from 'react-router-dom';

export default function Login() {
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const [error, setError] = useState(null);

    const handleSubmit = () => {
        if (email?.length < 1 && password?.length < 1) return;
        setError(null);

        auth(email, password)
            .then(r => {
                console.log(r.data);
                localStorage.setItem('refresh_token', r.data.refresh_token);
                localStorage.setItem('access_token', r.data.access_token);

                console.log('Авторизован');
                navigate('/');
            })
            .catch(err => {
                console.error(err);
                setError(err.response.data.message || 'Произошла ошибка ' + err.response.status);
            });
    };

    const authByEnter = (e) => e.key === 'Enter' && handleSubmit();

    return (
        <Container component="main" maxWidth="xs">
            {localStorage.access_token && localStorage.refresh_token && <Navigate to="/"/>}
            <Box
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center'
                }}
            >
                <Typography component="h1" variant="h5" color="primary" sx={{fontWeight: 500, mb: 1}}>
                    RuliKZ CRM
                </Typography>
                <Typography variant="body1">Система для онлайн-записи в автошколе</Typography>
                <Avatar sx={{m: 1, mt: 5, bgcolor: 'secondary.main'}}><LockOutlinedIcon/></Avatar>
                <Typography component="h1" variant="h5">Авторизация</Typography>
                {error && <Alert severity="error" sx={{mt: 3, mb: 1, width: '90%'}}>{error}</Alert>}
                <Box sx={{mt: 1}}>
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        label="Email адрес"
                        value={email}
                        onChange={(e) => setEmail(e.currentTarget.value)}
                        onKeyDown={authByEnter}
                        autoComplete="email"
                        autoFocus
                    />
                    <TextField
                        margin="dense"
                        required
                        fullWidth
                        value={password}
                        onChange={(e) => setPassword(e.currentTarget.value)}
                        onKeyDown={authByEnter}
                        label="Пароль"
                        type="password"
                        autoComplete="current-password"
                    />
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        onClick={handleSubmit}
                        sx={{mt: 2, mb: 2}}
                    >
                        Войти
                    </Button>
                    <Link href="/register" variant="body2">
                        Нет аккаунта? Зарегистрироваться
                    </Link>
                </Box>
            </Box>
        </Container>
    );
}