const initialState = {
    open: false,
    severity: 'error',
    message: ''
}

export const snackbarReducer = (state=initialState, action) => {
    switch(action.type) {
        case 'OPEN_SNACKBAR':
            return {open: true, severity: action.severity, message: action.message}
        case 'CLOSE_SNACKBAR':
            return {...state, open: false}
        default:
            return state
    }
}