import { CustomDataGrid } from '../CustomDataGrid';
import React, { useEffect, useState } from 'react';
import { deleteService, getStaffServices } from '../../utils/api';
import { Box, Button, IconButton } from '@mui/material';
import { Pagination } from '@mui/lab';
import EditIcon from '@mui/icons-material/Edit';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import CustomPagination from '../CustomPagination';
import ServiceDialog from '../Dialogs/ServiceDialog';
import { ConfirmationDialog } from '../ConfirmationDialog';
import { useDispatch } from 'react-redux';

export default function ServicesTable({staffId}) {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);
    const [modal, setModal] = useState(null);
    const [rows, setRows] = useState([]);

    useEffect(() => {
        updateDataGrid();
    }, [staffId]);

    const updateDataGrid = () => {
        setLoading(true);
        getStaffServices(staffId)
            .then(r => {
                console.log('services', r.data);
                setLoading(false);
                setRows(r.data.services);
            })
            .catch(err => {
                setLoading(false);
                console.error(err);
            });
    };

    const columns = [
        {
            field: 'actions',
            headerName: 'Действия',
            renderCell: ({row}) => {
                const deleteAction = () => {
                    deleteService(row.id)
                        .then(r => {
                            dispatch({type: 'OPEN_SNACKBAR', severity: 'success', message: 'Услуга удалена'});
                            setModal(null);
                            updateDataGrid();
                        });
                };
                return (
                    <div>
                        <IconButton color="primary" size="small" aria-label="delete"
                                    style={{zIndex: 100, marginRight: 5}}
                                    onClick={() => createService(row.id)}
                        >
                            <EditIcon fontSize="small"/>
                        </IconButton>
                        {/*<IconButton*/}
                        {/*    color="primary" size="small" aria-label="delete"*/}
                        {/*    style={{zIndex: 100, marginRight: 5}}*/}
                        {/*    onClick={() => setModal(<ConfirmationDialog*/}
                        {/*        title={'Удаление услуги #' + row.id}*/}
                        {/*        description={`Вы действительно хотите безвозвратно удалить услугу "${row.title}"?`}*/}
                        {/*        cancel={{title: 'Отменить', color: 'primary', action: () => setModal(null)}}*/}
                        {/*        confirm={{title: 'Удалить', color: 'error', action: deleteAction}}*/}
                        {/*    />)}*/}
                        {/*>*/}
                        {/*    <DeleteOutlineIcon fontSize="small"/>*/}
                        {/*</IconButton>*/}
                    </div>
                );
            },
            sortable: false,
            width: 120,
            headerAlign: 'center',
            filterable: false,
            align: 'center',
            disableColumnMenu: true,
            disableReorder: true
        },
        {field: 'id', headerName: 'ID', width: 130},
        {field: 'title', headerName: 'Название', width: 250},
        {
            field: 'duration_minutes',
            headerName: 'Длительность',
            width: 180,
            valueFormatter: ({value}) => value + ' минут'
        },
        {field: 'description', headerName: 'Описание', width: 400}
    ];

    const createService = (serviceId = null) => {
        const onCancel = () => {
            setModal(null);
        };

        const onSuccess = () => {
            setModal(null);
            updateDataGrid();
        };

        setModal(<ServiceDialog serviceId={serviceId} staffId={staffId} onCancel={onCancel} onSuccess={onSuccess}/>);
    };

    return (
        <Box sx={{mt: 1, mb: 2}}>
            {modal}
            <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexWrap: 'wrap'}}>
                <h1 style={{marginBottom: 20, marginTop: 20}}>Услуги</h1>
                <Button variant="contained" onClick={() => createService()}>Создать услугу</Button>
            </Box>
            <CustomDataGrid
                rows={rows}
                columns={columns}
                editable={false}
                pagination={false}
                size={100}
                components={{
                    Pagination: CustomPagination
                }}
                hideFooter={true}
                autoHeight
                loading={loading}
            />
        </Box>
    );
}