import { Box, Chip, CircularProgress, Paper, Typography } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider, PickersDay, StaticDatePicker } from '@mui/x-date-pickers';
import ruLocale from 'date-fns/locale/ru';
import React, { useEffect, useState } from 'react';
import { format } from 'date-fns';
import { getSchedule } from '../../utils/api';

export default function ScheduleDatePicker({staffId, onChange, readOnly}) {
    const [selectedMonth, setSelectedMonth] = useState(new Date());
    const [selectedDates, setSelectedDates] = useState([]);
    const [schedule, setSchedule] = useState({});
    const [loading, setLoading] = useState(true);

    const onSelect = (date) => {
        if (!readOnly) {
            const d = format(date, 'yyyy-MM-dd');
            const r = selectedDates.includes(d) ? selectedDates.filter(i => i !== d) : [...selectedDates, d];
            onChange(r);
            setSelectedDates(r);
        }
    };

    useEffect(() => {
        if (!selectedMonth) {
            setSelectedMonth(new Date());
            return;
        }

        setLoading(true);

        getSchedule(staffId, format(selectedMonth, 'yyyy-MM-dd'))
            .then(r => {
                console.log('getSchedule', r.data);
                setSchedule(r.data.schedule);
                setLoading(false);
            })
            .catch(err => {
                console.error(err);
                setLoading(false);
            });
    }, [selectedMonth]);

    const renderDay = (date, dates, pickersDayProps) => {
        // console.log(date, dates, pickersDayProps);

        // setSelectedDates(selected);
        const d = format(pickersDayProps.day, 'yyyy-MM-dd');

        const isSelected = selectedDates.includes(d);
        const isScheduled = schedule[d];

        const selectedStyle = isSelected ? {
            background: '#1565c0',
            color: 'white',
            '&:hover': {background: '#0d4380'}
        } : {};

        const scheduledStyle = isScheduled ? {background: '#a9ef8c', '&:hover': {background: '#a9ef8c'}} : {};

        return (
            <PickersDay
                {...pickersDayProps}
                // disableMargin
                sx={{...selectedStyle, ...scheduledStyle}}
                selected={false}
                disabled={isScheduled}
                // disableMargin
                // dayIsBetween={dayIsBetween}
                // isFirstDay={isFirstDay}
                // isLastDay={isLastDay}
            />
        );
    };

    const onMonthChange = (month) => {
        console.log('Month', month);
        setSelectedMonth(month);
    };

    return (
        <Box>
            <LocalizationProvider dateAdapter={AdapterDateFns} locale={ruLocale}>
                <Box sx={{mt: 1}}>
                    <Box sx={{mb: -3}}>
                        <StaticDatePicker
                            displayStaticWrapperAs="desktop"
                            value={null}
                            views={['day']} renderDay={renderDay}
                            onChange={onSelect}
                            allowSameDateSelection={true}
                            onMonthChange={onMonthChange}
                            defaultCalendarMonth={selectedMonth}
                            loading={loading}
                            // renderInput={(startProps, endProps) => (
                            //     <React.Fragment>
                            //         <TextField {...startProps} />
                            //         <Box sx={{mx: 2}}> to </Box>
                            //         <TextField {...endProps} />
                            //     </React.Fragment>
                            // )}
                        />
                    </Box>
                    {!readOnly &&
                        <Paper sx={{p: 2, '& >*': {display: 'flex', alignItems: 'center', mb: 1, '& >*': {mr: 1}}}}
                               elevation={2}>
                            <Box>
                                <Chip label="1" sx={{background: 'white'}}/>
                                <Typography>– Свободный день. Можно его выбрать</Typography>
                            </Box>
                            <Box>
                                <Chip label="1" color={'primary'}/>
                                <Typography>– Выбранный день</Typography>
                            </Box>
                            <Box>
                                <Chip label="1" sx={{background: '#a9ef8c'}}/>
                                <Typography>– Рабочий день. Расписание уже установлено</Typography>
                            </Box>
                        </Paper>
                    }
                </Box>
            </LocalizationProvider>
        </Box>
    );
}