import { Button, Container, Typography } from '@mui/material';
import BackLink from '../../../components/BackLink';
import AppointmentDatePicker from '../../../components/AppointmentDatePicker';
import React, { useState } from 'react';
import { useNavigate, Navigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

export default function SelectTimePage() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const appointment = useSelector(state => state.appointment);

    const [selectedDate, setSelectedDate] = useState(null);
    const [selectedTime, setSelectedTime] = useState(null);

    const onSelectTime = (date, time) => {
        console.log('onSelectTime', date, time);
        setSelectedDate(date);
        setSelectedTime(time);
    };

    const save = () => {
        if (!selectedTime || !selectedDate) return;
        dispatch({type: 'APPOINTMENT_SET_TIME', payload: {date: selectedDate, time: selectedTime}});
        navigate('/new');
    };

    const Content = <>
        <BackLink to="/new"/>
        <AppointmentDatePicker
            staffId={appointment.staff?.id}
            serviceId={appointment.service?.id}
            onChange={onSelectTime}
        />
        <Button color="primary" variant="contained" fullWidth sx={{mt: 3}} size="large"
                disabled={!selectedDate || !selectedTime} onClick={save}
        >
            Сохранить
        </Button>
    </>;

    return (
        <Container maxWidth="sm" sx={{mb: 8}}>
            {!appointment.staff?.id || !appointment.service?.id ? <Navigate to="/new"/> : Content}
        </Container>
    );
}