import { useState, useEffect } from 'react';
import {
    Avatar,
    Box,
    CircularProgress,
    Container, Divider,
    IconButton, Link, List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    Typography
} from '@mui/material';
import { getAvailableStaff } from '../../../utils/api';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import PersonIcon from '@mui/icons-material/Person';
import { useNavigate, Link as RouterLink } from 'react-router-dom';
import BackLink from '../../../components/BackLink';
import { useDispatch } from 'react-redux';

export default function SelectStaffPage() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);
    const [rows, setRows] = useState([]);

    useEffect(() => {
        getData();
    }, []);

    const getData = async () => {
        setLoading(true);
        getAvailableStaff()
            .then(r => {
                console.log('[getAvailableStaff]', r.data);
                setLoading(false);
                setRows(r.data.rows);
            })
            .catch(err => {
                setLoading(false);
            });
    };

    const onClick = (data) => {
        dispatch({type: 'APPOINTMENT_SET_STAFF', payload: data});
        navigate('/new');
    };

    return (
        <Container maxWidth="md">
            <BackLink to="/new"/>
            <Typography variant="h6">Выберите инструктора</Typography>
            {loading && <CircularProgress/>}
            {!loading && <List>
                {rows?.map(i => (
                    <ListItem
                        key={i.id}
                        disableGutters
                        divider
                        onClick={() => onClick(i)}
                        secondaryAction={
                            <IconButton onClick={() => onClick(i)}>
                                <ArrowForwardIosIcon fontSize="small"/>
                            </IconButton>
                        }
                    >
                        <ListItemButton>
                            <ListItemIcon>
                                <Avatar sx={{width: 35, height: 35}}/>
                            </ListItemIcon>
                            <Typography>{i.last_name} {i.first_name} {i.patronymic || ''}</Typography>
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
            }
        </Container>
    );
}