import { Button, CircularProgress, Container } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { getUser } from '../../utils/api';
import Schedule from '../../components/Schedule';
import FastSchedulingDialog from '../../components/Dialogs/FastSchedulingDialog';
import CreateAppointmentDialog from '../../components/Dialogs/CreateAppointmentDialog';
import { useSelector } from 'react-redux';

export default function MySchedule() {
    const accountInfo = useSelector(state => state.account);
    const [modal, setModal] = useState(null);
    const [date, setDate] = useState(new Date());

    const fastScheduling = () => {
        const onCancel = () => {
            setModal(null);
        };

        const onSuccess = () => {
            setModal(null);
            setDate(new Date());
        };

        setModal(<FastSchedulingDialog staffId={accountInfo.id} onSuccess={onSuccess} onCancel={onCancel}/>);
    };

    const newAppointment = () => {
        const onCancel = () => {
            setModal(null);
        };

        const onSuccess = () => {
            setModal(null);
            setDate(new Date());
        };

        setModal(<CreateAppointmentDialog staffId={accountInfo.id} onSuccess={onSuccess} onCancel={onCancel}/>);
    };

    return (
        <Container>
            {modal}
            <h1>Мой график работы</h1>
            <Button variant="outlined" sx={{mb: 2, mr: 2}} onClick={fastScheduling}>
                Добавить рабочие дни
            </Button>
            <Button variant="outlined" sx={{mb: 2}} onClick={newAppointment}>Новая запись</Button>
            {accountInfo.id && <Schedule staffId={accountInfo.id} initialDate={date}/>}
        </Container>
    );
}