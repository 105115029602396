import { Box, Button, Chip, Grid, Paper, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import ruLocale from 'date-fns/locale/ru';
import { LocalizationProvider, PickersDay, StaticDatePicker } from '@mui/x-date-pickers';
import { appointmentGetMonthData, getSchedule } from '../../utils/api';
import { format, parse } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';

export default function AppointmentDatePicker({staffId, serviceId, month, appointmentId, onChange}) {
    const [selectedMonth, setSelectedMonth] = useState(new Date());
    const [selectedDate, setSelectedDate] = useState(null);
    const [selectedSchedule, setSelectedSchedule] = useState(null);
    const [selectedTime, setSelectedTime] = useState(null);
    const [schedule, setSchedule] = useState({});
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (!selectedMonth) {
            setSelectedMonth(new Date());
            return;
        }
        updateSchedule();
    }, [selectedMonth]);

    useEffect(() => {
        setSelectedMonth(new Date());
        setSelectedDate(null);
        setSelectedTime(null);
        onChange && onChange(null, null);
        updateSchedule();
    }, [staffId, serviceId]);

    const onMonthChange = (month) => {
        console.log('Month', month);
        setSelectedMonth(month);
    };

    const updateSchedule = () => {
        setLoading(true);
        appointmentGetMonthData(staffId, serviceId, format(new Date(selectedMonth), 'yyyy-MM-dd'), appointmentId)
            .then(r => {
                console.log('appointmentGetMonthData', r.data);
                setSchedule(r.data.data);
                setLoading(false);
            })
            .catch(err => {
                console.error(err);
                setLoading(false);
            });
    };

    const renderDay = (date, dates, pickersDayProps) => {
        // console.log(date, dates, pickersDayProps);

        // setSelectedDates(selected);
        const d = format(pickersDayProps.day, 'yyyy-MM-dd');
        const times = schedule[d];

        const isWorkingDay = times !== null;
        const isAvailable = isWorkingDay && times?.length;
        const isSelected = selectedDate === d;

        const selectedStyle = isSelected ? {
            background: '#1565c0',
            color: 'white',
            '&:hover': {background: '#0d4380'}
        } : {};

        const availableStyle = isAvailable && {background: 'rgb(202,216,255)'};


        return (
            <PickersDay
                {...pickersDayProps}
                // disableMargin
                sx={{...availableStyle, ...selectedStyle}}
                selected={false}
                // disabled={!isWorkingDay}
                // disableMargin
                // dayIsBetween={dayIsBetween}
                // isFirstDay={isFirstDay}
                // isLastDay={isLastDay}
            />
        );
    };

    const onSelect = (date) => {
        const d = format(date, 'yyyy-MM-dd');
        const times = schedule[d];
        console.log('Selected', d);
        console.log(schedule[d]);
        setSelectedDate(d);
        setSelectedTime(null);
        setSelectedSchedule(times && times.length ? times : null);
        onChange && onChange(d, null);
        // const r = selectedDates.includes(d) ? selectedDates.filter(i => i !== d) : [...selectedDates, d];
        // onChange(r);
        // setSelectedDates(r);
    };

    const onSelectTime = (time) => {
        setSelectedTime(time);
        onChange && onChange(selectedDate, time);
    };

    return (
        <Box>
            <Typography variant="h6">1. Выберите дату</Typography>
            <LocalizationProvider dateAdapter={AdapterDateFns} locale={ruLocale}>
                <Box sx={{mt: 1}}>
                    <Box sx={{mb: -3}}>
                        <StaticDatePicker
                            displayStaticWrapperAs="desktop"
                            value={null}
                            views={['day']} renderDay={renderDay}
                            onChange={onSelect}
                            allowSameDateSelection={true}
                            onMonthChange={onMonthChange}
                            defaultCalendarMonth={selectedMonth}
                            loading={loading}
                            // renderInput={(startProps, endProps) => (
                            //     <React.Fragment>
                            //         <TextField {...startProps} />
                            //         <Box sx={{mx: 2}}> to </Box>
                            //         <TextField {...endProps} />
                            //     </React.Fragment>
                            // )}
                        />
                    </Box>
                    {/*<Box sx={{p: 2, '& >*': {display: 'flex', alignItems: 'center', mb: 1, '& >*': {mr: 1}}}}>*/}
                    {/*    <Box>*/}
                    {/*        <Box label="1"*/}
                    {/*             sx={{background: 'rgb(202,216,255)', width: 20, height: 20, borderRadius: '100%'}}/>*/}
                    {/*        <Typography>– Доступна запись</Typography>*/}
                    {/*    </Box>*/}
                    {/*</Box>*/}
                </Box>
            </LocalizationProvider>
            {selectedDate && <Box>
                {!selectedSchedule ? <Typography>Запись на этот день недоступна</Typography> : <Box>
                    <Typography variant="h6" sx={{mb: 2}}>2. Выберите время</Typography>
                    <Typography sx={{mt: 0, mb: 1.3}} variant="body2"><b>Часовой пояс: Алматы (GMT+5)</b></Typography>
                    <Grid container spacing={1}>
                        {selectedSchedule.map((i) => {
                            return (
                                <Grid item xs={3} key={i}>
                                    <Button sx={{m: .5}}
                                            variant={i === selectedTime ? 'contained' : 'outlined'}
                                            color="primary" onClick={() => onSelectTime(i)}
                                            fullWidth>
                                        {formatInTimeZone(parse(i, 'HH:mm:ssx', new Date()), 'Asia/Yekaterinburg', 'HH:mm')}
                                    </Button>
                                </Grid>);
                        })}
                    </Grid>
                </Box>}
            </Box>}
        </Box>
    );
}
