import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    LinearProgress,
    TextField,
    Button,
    Alert
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import React, { useState } from 'react';
import { changeMyPassword, changeUserPassword } from '../../utils/api';
import { useDispatch } from 'react-redux';

export default function ChangePasswordDialog({onCancel, onSuccess, id, isAdmin = false}) {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [newPassword2, setNewPassword2] = useState('');

    const save = () => {
        if (!isAdmin && !oldPassword?.length) return setError('Не введён текущий пароль');
        if (!newPassword?.length) return setError('Не введён текущий пароль');
        if (newPassword?.length < 8) return setError('Минимальная длина пароля: 8 символов');
        if (newPassword !== newPassword2) return setError('Пароли не совпадают');

        setError(null);
        setLoading(true);

        if (isAdmin) {
            changeUserPassword(id, newPassword)
                .then(r => {
                    console.log('[changeUserPassword]', r.data);
                    setLoading(false);
                    dispatch({type: 'OPEN_SNACKBAR', severity: 'success', message: r.data.message});
                    onSuccess();
                })
                .catch(err => {
                    setLoading(false);
                    console.error(err);
                });
        } else {
            changeMyPassword(oldPassword, newPassword)
                .then(r => {
                    console.log('[changeMyPassword]', r.data);
                    setLoading(false);
                    dispatch({type: 'OPEN_SNACKBAR', severity: 'success', message: r.data.message});
                    onSuccess();
                })
                .catch(err => {
                    setLoading(false);
                    console.error(err);
                });
        }
    };

    return (
        <Dialog open={true} onClose={onCancel} maxWidth="xs" disableEnforceFocus fullWidth>
            <DialogTitle>Изменить пароль</DialogTitle>
            <DialogContent>
                {!isAdmin &&
                    <TextField label="Текущий пароль" value={oldPassword} fullWidth
                               variant="standard" sx={{mt: 1, mb: 3}} type="password"
                               onChange={e => setOldPassword(e.currentTarget.value)}/>}
                <TextField label="Новый пароль" value={newPassword} fullWidth variant="standard" sx={{mt: 1, mb: 0}}
                           type="password" onChange={e => setNewPassword(e.currentTarget.value)}/>
                <TextField label="Повтор пароля" value={newPassword2} fullWidth variant="standard" sx={{mt: 2, mb: 2}}
                           type="password" onChange={e => setNewPassword2(e.currentTarget.value)}/>

                {error && <Alert severity="error" sx={{mt: 3, mb: 1, maxWidth: 800}}>{error}</Alert>}
            </DialogContent>
            <DialogActions>
                <Button onClick={onCancel}>Отменить</Button>
                <LoadingButton onClick={save} color="primary" variant="contained" loading={loading}>
                    Изменить
                </LoadingButton>
            </DialogActions>
            {loading && <LinearProgress style={{height: 6}}/>}
        </Dialog>
    );
}