import {
    Alert, Box,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    LinearProgress, TextField, Typography
} from '@mui/material';
import { format } from 'date-fns';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import AppointmentDatePicker from '../AppointmentDatePicker';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { createAppointment, getServicesList, searchUsers } from '../../utils/api';
import { Autocomplete } from '@mui/lab';
import LoadingButton from '@mui/lab/LoadingButton';

export default function CreateAppointmentDialog({onCancel, onSuccess, staffId, clientId}) {
    const dispatch = useDispatch();
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
    const [modal, setModal] = useState(null);

    const [staffList, setStaffList] = useState([]);
    const [selectedStaff, setSelectedStaff] = useState(null);

    const [clientsList, setClientsList] = useState([]);
    const [selectedClient, setSelectedClient] = useState(null);

    const [servicesList, setServicesList] = useState([]);
    const [selectedService, setSelectedService] = useState(null);

    const [selectedDate, setSelectedDate] = useState(null);
    const [selectedTime, setSelectedTime] = useState(null);

    useEffect(() => {
        searchUsers({account_type: 5, count: 500, sc: 'id', sd: 0})
            .then(r => {
                console.log('Search Staff Users', r.data);
                setStaffList(r.data.rows);
                setLoading(false);
                if (staffId) {
                    const staff = r.data.rows.filter(i => i.id === staffId)[0];
                    if (staff) setSelectedStaff(staff);
                }
            });
    }, [staffId]);

    useEffect(() => {
        searchUsers({account_type: 1, count: 500, sc: 'id', sd: 0})
            .then(r => {
                console.log('Search Clients', r.data);
                setClientsList(r.data.rows);
                setLoading(false);
                if (clientId) {
                    const client = r.data.rows.filter(i => i.id === clientId)[0];
                    if (client) setSelectedClient(client);
                }
            });
    }, [clientId]);

    useEffect(() => {
        setSelectedService(null);
        setSelectedDate(null);
        setSelectedTime(null);
        if (selectedStaff?.id) {
            getServicesList(selectedStaff.id)
                .then(r => {
                    console.log('getServicesList', r.data);
                    setServicesList(r.data.services);
                });
        }
    }, [selectedStaff]);

    useEffect(() => {
        setSelectedDate(null);
        setSelectedTime(null);
    }, [selectedService]);


    const onSelectTime = (date, time) => {
        console.log('onSelectTime', date, time);
        setSelectedDate(date);
        setSelectedTime(time);
    };

    const save = () => {
        setLoading(true);
        createAppointment(selectedClient.id, selectedStaff.id, selectedService.id, selectedDate, selectedTime)
            .then(r => {
                console.log('createAppointment', r.data);
                setLoading(false);
                dispatch({type: 'OPEN_SNACKBAR', severity: 'success', message: 'Клиент успешно записан'});
                onSuccess(r.data.appointment, r.data.service);
            })
            .catch(err => {
                console.error(err);
                setLoading(false);
                setError(err.response.data.message || 'Произошла ошибка ' + err.response.status);
            });
    };

    return (
        <Dialog open={true} onClose={onCancel} maxWidth="xs" scroll="body" disableEnforceFocus fullWidth>
            {modal}
            <DialogTitle>Новая запись</DialogTitle>
            <DialogContent>
                <Autocomplete
                    value={selectedClient}
                    options={clientsList}
                    sx={{mt: 2}}
                    onChange={(event, newValue) => setSelectedClient(newValue)}
                    getOptionLabel={(option) => `${option.last_name || ''} ${option.first_name || ''} ${option.patronymic || ''}`}
                    renderInput={(params) => <TextField {...params} label="Клиент" fullWidth/>}
                    renderOption={(props, option) => (
                        <Box component="li" {...props}>
                            <Box>
                                <Typography>{option.last_name} {option.first_name} {option.patronymic}</Typography>
                                <Typography variant="subtitle2" style={{color: 'gray', display: 'block'}}>
                                    Телефон: {option.phone}
                                </Typography>
                            </Box>
                        </Box>
                    )}
                />
                <Autocomplete
                    value={selectedStaff}
                    disabled={!selectedClient}
                    options={staffList}
                    sx={{mt: 4}}
                    onChange={(event, newValue) => setSelectedStaff(newValue)}
                    getOptionLabel={(option) => `${option.last_name || ''} ${option.first_name || ''} ${option.patronymic || ''}`}
                    renderInput={(params) => <TextField {...params} label="Сотрудник" fullWidth/>}
                    renderOption={(props, option) => (
                        <Box component="li" {...props}>
                            <Box>
                                <Typography>{option.last_name} {option.first_name} {option.patronymic}</Typography>
                                <Typography variant="subtitle2" style={{color: 'gray', display: 'block'}}>
                                    Телефон: {option.phone}
                                </Typography>
                            </Box>
                        </Box>
                    )}
                />
                <Autocomplete
                    value={selectedService}
                    options={servicesList}
                    disabled={!selectedStaff || !selectedClient}
                    sx={{mt: 2, mb: 3}}
                    onChange={(event, newValue) => setSelectedService(newValue)}
                    getOptionLabel={(option) => `${option.title} (${option.duration_minutes} мин.)`}
                    renderInput={(params) => <TextField {...params} label="Услуга" fullWidth/>}
                    renderOption={(props, option) => (
                        <Box component="li" {...props}>
                            <Box>
                                <Typography>{option.title}</Typography>
                                <Typography variant="subtitle2" style={{color: 'gray', display: 'block'}}>
                                    {option.duration_minutes} минут
                                </Typography>
                            </Box>
                        </Box>
                    )}
                />

                {selectedService && <AppointmentDatePicker serviceId={selectedService.id} staffId={selectedStaff.id}
                                                           onChange={onSelectTime}/>}
                {error && <Alert severity="error" sx={{mt: 3, mb: 1, maxWidth: 800}}>{error}</Alert>}
            </DialogContent>
            <DialogActions>
                <Button onClick={onCancel}>Закрыть</Button>
                <LoadingButton onClick={save} color="primary" variant="contained" loading={loading}
                               disabled={!selectedClient || !selectedStaff || !selectedService || !selectedDate || !selectedTime}>
                    Записать
                </LoadingButton>
            </DialogActions>
            {loading && <LinearProgress style={{height: 6}}/>}
        </Dialog>
    );
}