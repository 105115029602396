import { Alert, Button, Dialog, DialogActions, DialogContent, DialogTitle, LinearProgress } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import React, { useState } from 'react';
import AppointmentDatePicker from '../AppointmentDatePicker';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

export default function AppointmentTimeDialog({onSuccess, onCancel, appointmentId, staffId, serviceId}) {
    const [selectedDate, setSelectedDate] = useState(null);
    const [selectedTime, setSelectedTime] = useState(null);

    const onSelectTime = (date, time) => {
        console.log('onSelectTime', date, time);
        setSelectedDate(date);
        setSelectedTime(time);
    };

    const save = () => {
        onSuccess(selectedDate, selectedTime);
    };

    return (
        <Dialog open={true} onClose={onCancel} maxWidth="xs" scroll="body" disableEnforceFocus fullWidth>
            <DialogTitle>Выбор даты и времени</DialogTitle>
            <DialogContent>
                <AppointmentDatePicker
                    staffId={staffId}
                    serviceId={serviceId}
                    appointmentId={appointmentId}
                    onChange={onSelectTime}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={onCancel}>Отменить</Button>
                <LoadingButton onClick={save} color="primary" variant="contained"
                               disabled={!selectedDate || !selectedTime}>
                    Сохранить
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
}