import React from 'react';
import { connect } from 'react-redux';
import { Alert, Snackbar } from '@mui/material';

function MySnackbar({open, severity, message, close}) {
    return (
        <Snackbar open={open} autoHideDuration={5000} onClose={() => close()} style={{zIndex: 1000000}}
                  anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'center'
                  }}>
            <Alert onClose={() => close()} severity={severity}>
                {message}
            </Alert>
        </Snackbar>
    );
}

const mapDispatchToProps = (dispatch) => {
    return {
        close: () => dispatch({type: 'CLOSE_SNACKBAR'})
    };
};
const mapStateToProps = (state) => {
    return {
        open: state.snackbar.open,
        severity: state.snackbar.severity,
        message: state.snackbar.message
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(MySnackbar);