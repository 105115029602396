import ServicesTable from '../../../components/ServicesTable';
import { Container } from '@mui/material';
import { useSelector } from 'react-redux';

export default function MyServices() {
    const accountInfo = useSelector(state => state.account);

    return (
        <Container maxWidth="xl">
            <ServicesTable staffId={accountInfo.id}/>
        </Container>
    )
}