import { Link } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

export default function BackLink({to, ...props}) {
    return (
        <Link underline="none" {...props} component={RouterLink} to={to}
              sx={{mt: 3, mb: 2, display: 'flex', alignItems: 'center', fontSize: 18}}>
            <ArrowBackIcon sx={{mr: 1}}/>
            Назад
        </Link>
    );
}