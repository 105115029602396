import { useEffect, useState } from 'react';
import { CustomDataGrid } from '../CustomDataGrid';
import { searchAppointments, searchUsers } from '../../utils/api';
import { useNavigate } from 'react-router-dom';
import queryString from 'query-string';
import { format, parse } from 'date-fns';
import { Avatar, Box, Button, Chip, FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { formatInTimeZone } from 'date-fns-tz';
import CreateAppointmentDialog from '../Dialogs/CreateAppointmentDialog';
import EditAppointmentDialog from '../Dialogs/EditAppointmentDialog';

export default function AppointmentsGrid({ clientId, staffId, label = 'История записей' }) {
    const navigate = useNavigate();
    const [modal, setModal] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [rows, setRows] = useState([]);
    const [total, setTotal] = useState(undefined);
    const [sortModel, setSortModel] = useState([]);
    const [query, setQuery] = useState('');
    const [selectedStatus, setSelectedStatus] = useState(0);

    useEffect(() => {
        updateDataGrid();
    }, [query, selectedStatus]);

    const chips = {
        1: <Chip label="Новая запись" color="primary"/>,
        2: <Chip label="Запись подтверждена" color="success"/>,
        3: <Chip label="Запись отменена" color="error"/>
    };

    const updateDataGrid = () => {
        setIsLoading(true);

        searchAppointments({ clientId, staffId, q: query.length ? query : null, status: selectedStatus })
            .then(r => {
                console.log('[DataGrid]:', r.data);
                setRows(r.data.rows);
                setTotal(r.data.total);
                setIsLoading(false);
            })
            .catch(err => {
                console.error('[DataGrid]:', err.response);
                setIsLoading(false);
            });
    };

    const columns = [
        { field: 'id', headerName: 'ID', width: 130 },
        {
            field: 'client', headerName: 'Клиент', width: 250, sortable: false,
            renderCell: ({ row }) => `${row.client.last_name} ${row.client.first_name}`, hide: !!clientId
        },
        {
            field: 'staff', headerName: 'Сотрудник', width: 250, sortable: false,
            renderCell: ({ row }) => `${row.staff.last_name} ${row.staff.first_name}`, hide: !!staffId
        },
        {
            field: 'status', headerName: 'Статус', width: 250, sortable: false,
            renderCell: ({ row }) => chips[row.status]
        },
        {
            field: 'date', headerName: 'Дата', width: 150, sortable: false,
            renderCell: ({ row }) => format(new Date(row.date), 'dd.MM.yyyy')
        },
        {
            field: 'start_time', headerName: 'Время', width: 200, sortable: false,
            renderCell: ({ row }) => [
                formatInTimeZone(parse(row.start_time, 'HH:mm:ssx', new Date()), 'Asia/Yekaterinburg', 'HH:mm'),
                formatInTimeZone(parse(row.end_time, 'HH:mm:ssx', new Date()), 'Asia/Yekaterinburg', 'HH:mm')
            ].join(' – ')
        },
        {
            field: 'service', headerName: 'Услуга', width: 200, sortable: false,
            renderCell: ({ row }) => `${row.service.title}`
        }
        // {
        //     field: 'last_name', headerName: 'ФИО', width: 400,
        //     renderCell: ({row}) => {
        //         return (
        //             <>
        //                 <Avatar sx={{width: 30, height: 30, mr: 1.5}}/>
        //                 {row.last_name} {row.first_name} {row.patronymic}
        //             </>
        //         );
        //     }
        // },
        // {field: 'phone', headerName: 'Телефон', width: 200, sortable: false},
        // {
        //     field: 'register_date',
        //     headerName: 'Дата регистрации',
        //     width: 200,
        //     valueFormatter: (params) => {
        //         const date = new Date(params.value);
        //         return format(date, 'dd.MM.yyyy');
        //     }
        // },
    ];

    const createAppointment = () => {
        const onCancel = () => {
            setModal(null);
        };

        const onSuccess = () => {
            setModal(null);
            updateDataGrid();
        };

        setModal(<CreateAppointmentDialog
            onSuccess={onSuccess} onCancel={onCancel}
            staffId={staffId} clientId={clientId}
        />);
    };

    const editAppointment = (appointmentId) => {
        const onCancel = () => {
            setModal(null);
        };

        const onSuccess = () => {
            setModal(null);
            updateDataGrid();
        };

        setModal(<EditAppointmentDialog
            onSuccess={onSuccess} onCancel={onCancel}
            appointmentId={appointmentId}
        />);
    };

    return (
        <Box>
            {modal}
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexWrap: 'wrap' }}>
                <h1 style={{ marginBottom: 20, marginTop: 20 }}>{label}</h1>
                <Button variant="contained" onClick={createAppointment}>Новая запись</Button>
            </Box>
            <TextField
                variant="outlined" label="Поиск по базе" size="medium"
                sx={{ mb: 1, mt: 1, mr: 1, width: 350, maxWidth: '80%' }}
                onChange={e => setQuery(e.currentTarget.value)}
                value={query}
            />
            <FormControl sx={{ mt: 1, mb: 1, width: 350, maxWidth: '80%' }}>
                <InputLabel id="appointments-sort-status-label">Статус записей</InputLabel>
                <Select label="Статус записей" labelId="appointments-sort-status-label" value={selectedStatus}
                        onChange={(e) => setSelectedStatus(e.target.value)}>
                    <MenuItem value={0}>Все</MenuItem>
                    <MenuItem value={1}>Новые</MenuItem>
                    <MenuItem value={2}>Подтверждённые</MenuItem>
                    <MenuItem value={3}>Отменённые</MenuItem>
                </Select>
            </FormControl>
            <CustomDataGrid
                rows={rows}
                columns={columns}
                editable={false}
                // autoHeight
                autoPageSize
                pagination
                // rowsPerPageOptions={[25, 50, 100, 150, 200]}
                onRowClick={(row) => editAppointment(row.id)}
                sx={{ '& .MuiDataGrid-row': { cursor: 'pointer' }, height: '80vh' }}
                loading={isLoading}
            />
        </Box>
    );
}
