import { useState } from 'react';
import { Box, Button, Container } from '@mui/material';
import AppointmentsGrid from '../../components/AppointmentsGrid';
import CreateAppointmentDialog from '../../components/Dialogs/CreateAppointmentDialog';
import { useSelector } from 'react-redux';
import StaffStats from '../../components/StaffStats';

export default function Appointments() {
    const [modal, setModal] = useState(null);
    const accountInfo = useSelector(state => state.account);
    const isManager = accountInfo.account_type === 5;

    const createAppointment = () => {
        const onCancel = () => {
            setModal(null);
        };

        const onSuccess = () => {
            setModal(null);
        };

        setModal(<CreateAppointmentDialog onSuccess={onSuccess} onCancel={onCancel}/>);
    };


    return (
        <Container component="main" maxWidth="xl">
            {modal}
            {isManager && <StaffStats staffId={accountInfo?.id}/>}
            <AppointmentsGrid label={isManager ? 'Мои записи' : 'Все записи'}
                              staffId={isManager ? accountInfo.id : null}/>
        </Container>
    );
}