import { createTheme } from '@mui/material/styles';
import { blue, orange, red } from '@mui/material/colors';

export const theme = createTheme({
    palette: {
        primary: {
            main: blue[800]
        },
        secondary: {
            main: blue[800]
        },
        warning: {
            main: orange[800]
        },
        error: {
            main: red[500]
        }
    }
});