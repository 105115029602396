import { useLocation } from 'react-router-dom';
import { Typography } from '@mui/material';

export default function Main() {
    const location = useLocation();
    console.log(location);

    return (
        <>
            <h2>404 → Страница не найдена</h2>
            <Typography>Страница <i>"{location.pathname}"</i> не найдена</Typography>
        </>
    );
}