import { getMyAccountInfo } from '../../utils/api';

const initialState = {
    'id': null,
    'first_name': '',
    'last_name': '',
    'patronymic': '',
    'account_type': null,
    'email': '',
    'phone': ''
};

export const accountReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_ACCOUNT_DATA':
            return action.payload;
        case 'CLEAR_USER_DATA':
            return {...initialState};
        default:
            return state;
    }
};