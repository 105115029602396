import { DataGrid, ruRU } from '@mui/x-data-grid';
import React from 'react';

const GRID_LOCALE_TEXT = {
    // Root
    noRowsLabel: 'Нет данных',
    noResultsOverlayLabel: 'Нет данных по запросу',
    errorOverlayDefaultLabel: 'Произошла ошибка',

    // Density selector toolbar button text
    toolbarDensity: 'Размер',
    toolbarDensityLabel: 'Размер',
    toolbarDensityCompact: 'Компактный',
    toolbarDensityStandard: 'Стандартный',
    toolbarDensityComfortable: 'Комфортный',

    // Columns selector toolbar button text
    toolbarColumns: 'Колонки',
    toolbarColumnsLabel: 'Колонки',

    // Filters toolbar button text
    toolbarFilters: 'Фильтры',
    toolbarFiltersLabel: 'Показать фильтры',
    toolbarFiltersTooltipHide: 'Скрыть фильтры',
    toolbarFiltersTooltipShow: 'Показать фильтры',
    toolbarFiltersTooltipActive: (count) =>
        count !== 1 ? `${count} активных фильтров` : `${count} активный фильтр`,

    // Export selector toolbar button text
    toolbarExport: 'Экспорт',
    toolbarExportLabel: 'Экспорт',
    toolbarExportCSV: 'Скачать как таблицу CSV',

    // Columns panel text
    columnsPanelTextFieldLabel: 'Найти колонку',
    columnsPanelTextFieldPlaceholder: 'Название колонки',
    columnsPanelDragIconLabel: 'Переставить колонки',
    columnsPanelShowAllButton: 'Показать все',
    columnsPanelHideAllButton: 'Скрыть все',

    // Filter panel text
    filterPanelAddFilter: 'Добавить фильтр',
    filterPanelDeleteIconLabel: 'Удалить',
    filterPanelOperators: 'Операторы',
    filterPanelOperatorAnd: 'И',
    filterPanelOperatorOr: 'Или',
    filterPanelColumns: 'Колонка',
    filterPanelInputLabel: 'Значение',
    filterPanelInputPlaceholder: 'Значение',

    // Filter operators text
    filterOperatorContains: 'содержит',
    filterOperatorEquals: 'равен',
    filterOperatorStartsWith: 'начинается с',
    filterOperatorEndsWith: 'заканчивается на',
    filterOperatorIs: 'равен',
    filterOperatorNot: 'не равен',
    filterOperatorAfter: 'после',
    filterOperatorOnOrAfter: 'is on or after',
    filterOperatorBefore: 'до',
    filterOperatorOnOrBefore: 'is on or before',
    filterOperatorIsEmpty: 'пустой',
    filterOperatorIsNotEmpty: 'не пустой',

    // Filter values text
    filterValueAny: 'Любой',
    filterValueTrue: 'Да',
    filterValueFalse: 'Нет',

    // Column menu text
    columnMenuLabel: 'Меню',
    columnMenuShowColumns: 'Управление колонками',
    columnMenuFilter: 'Фильтр',
    columnMenuHideColumn: 'Скрыть',
    columnMenuUnsort: 'Отменить сортировку',
    columnMenuSortAsc: 'Сорт. по возрастанию',
    columnMenuSortDesc: 'Сорт. по убыванию',

    // Column header text
    columnHeaderFiltersTooltipActive: (count) =>
        count !== 1 ? `${count} активных фильтров` : `${count} активный фильтр`,
    columnHeaderFiltersLabel: 'Показать фильтры',
    columnHeaderSortIconLabel: 'Сортировать',

    // Rows selected footer text
    footerRowSelected: (count) =>
        count !== 1
            ? `${count.toLocaleString()} строк выбрано`
            : `${count.toLocaleString()} строка выбрана`,

    // Total rows footer text
    footerTotalRows: 'Найдено:',

    // Total visible rows footer text
    footerTotalVisibleRows: (visibleCount, totalCount) =>
        `${visibleCount.toLocaleString()} из ${totalCount.toLocaleString()}`,

    // Checkbox selection text
    checkboxSelectionHeaderName: 'Checkbox selection',

    // Boolean cell text
    booleanCellTrueLabel: 'Да',
    booleanCellFalseLabel: 'Нет',

    // Actions cell more text
    actionsCellMore: 'more',

    // Used core components translation keys
    MuiTablePagination: {}
};

export const CustomDataGrid = ({...props}) => (
    <DataGrid
        {...props}
        // localeText={GRID_LOCALE_TEXT}
        localeText={ruRU.components.MuiDataGrid.defaultProps.localeText}
    />
);