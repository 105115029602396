import { Box, Button, CircularProgress, Container } from '@mui/material';
import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { getUser } from '../../../utils/api';
import EditAccount from '../../../components/EditAccount';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { CalendarPicker, LocalizationProvider, PickersDay } from '@mui/x-date-pickers';
import ServicesTable from '../../../components/ServicesTable';
import AppointmentsGrid from '../../../components/AppointmentsGrid';
import CreateAppointmentDialog from '../../../components/Dialogs/CreateAppointmentDialog';
import ScheduleDatePicker from '../../../components/ScheduleDatePicker';
import StaffStats from '../../../components/StaffStats';

export default function StaffPage({ match }) {
    const params = useParams();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [modal, setModal] = useState(null);
    const [data, setData] = useState({});

    useEffect(() => {
        getUser(params.id)
            .then(r => {
                console.log('[getUser]', r.data);
                setData(r.data);
                setLoading(false);
            })
            .catch(err => {

            });
    }, [params]);

    const renderDay = (date, selectedDates, pickersDayProps) => {
        console.log(date, selectedDates, pickersDayProps);

        return (
            <PickersDay
                {...pickersDayProps}
                // disableMargin
                // sx={{border: '1px solid black'}}
                // disableMargin
                // dayIsBetween={dayIsBetween}
                // isFirstDay={isFirstDay}
                // isLastDay={isLastDay}
            />
        );
    };

    return (
        <Container component="main" maxWidth="xl">
            {modal}
            <h1 style={{ marginBottom: 20, marginTop: 20 }}>Сотрудник #{params.id}</h1>
            {loading && <CircularProgress/>}
            {data.id && <>
                <EditAccount initialData={data}/>
                <StaffStats staffId={data.id}/>
                <ServicesTable staffId={data.id}/>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexWrap: 'wrap' }}>
                    <h1 style={{ marginBottom: 20, marginTop: 20 }}>График работы</h1>
                    <Button variant="contained" onClick={() => navigate('./schedule')}>Управление расписанием</Button>
                </Box>
                <ScheduleDatePicker staffId={data.id} readOnly/>
                <AppointmentsGrid staffId={data.id}/>
            </>}
        </Container>
    );
}