import { Box, Button, CircularProgress, Container } from '@mui/material';
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { getUser } from '../../../utils/api';
import EditAccount from '../../../components/EditAccount';

export default function AdminPage({match}) {
    const params = useParams();
    const [loading, setLoading] = useState(true);
    const [modal, setModal] = useState(null);
    const [data, setData] = useState({});

    useEffect(() => {
        getUser(params.id)
            .then(r => {
                console.log('[getUser]', r.data);
                setData(r.data);
                setLoading(false);
            })
            .catch(err => {

            });
    }, [params]);

    return (
        <Container component="main" maxWidth="xl">
            {modal}
            <h1 style={{marginBottom: 20, marginTop: 20}}>Администратор #{params.id}</h1>
            {loading && <CircularProgress/>}
            {data.id && <EditAccount initialData={data}/>}
        </Container>
    );
}