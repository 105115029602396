import React, { useEffect } from 'react';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import PrivateRoute from '../PrivateRoute';
import Navigation from '../Navigation';
import Main from '../../pages/Main';
import Staff from '../../pages/Staff';
import Account from '../../pages/Account';
import Admins from '../../pages/Admins';
import Clients from '../../pages/Clients';
import ClientPage from '../../pages/Clients/ClientPage';
import AdminPage from '../../pages/Admins/AdminPage';
import StaffPage from '../../pages/Staff/StaffPage';
import SchedulePage from '../../pages/Staff/StaffPage/SchedulePage';
import { useDispatch, useSelector } from 'react-redux';
import { Box, CircularProgress, Typography } from '@mui/material';
import { getMyAccountInfo } from '../../utils/api';
import UserNavigation from '../UserNavigation';
import NewAppointment from '../../pages/NewAppointment';
import SelectStaffPage from '../../pages/NewAppointment/SelectStaffPage';
import SelectServicePage from '../../pages/NewAppointment/SelectServicePage';
import SelectTimePage from '../../pages/NewAppointment/SelectTimePage';
import MyAppointments from '../../pages/MyAppointments';
import Appointments from '../../pages/Appointments';
import MyServices from '../../pages/StaffPages/MyServices';
import MySchedule from '../../pages/MySchedule';

function AdminArea() {
    const dispatch = useDispatch();
    const accountInfo = useSelector(state => state.account);

    useEffect(() => {
        getMyAccountInfo()
            .then(r => dispatch({type: 'SET_ACCOUNT_DATA', payload: r.data}));
    }, []);


    const Loading = <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh'}}>
        <CircularProgress/>
    </Box>;

    const StaffNavigation = <Navigation>
        <Routes>
            <Route exact path={'/'} element={<Navigate to="/clients"/>}/>
            <Route exact path={'/*'} element={<Main/>}/>
            <Route exact path={'/staff'} element={<Staff/>}/>
            <Route exact path={'/staff/:id'} element={<StaffPage/>}/>
            <Route exact path={'/staff/:id/schedule'} element={<SchedulePage/>}/>
            <Route exact path={'/admins'} element={<Admins/>}/>
            <Route exact path={'/admins/:id'} element={<AdminPage/>}/>
            <Route exact path={'/clients'} element={<Clients/>}/>
            <Route exact path={'/clients/:id'} element={<ClientPage/>}/>
            <Route exact path={'/appointments'} element={<Appointments/>}/>
            <Route exact path={'/account'} element={<Account/>}/>
            <Route exact path={'/services'} element={<MyServices/>}/>
            <Route exact path={'/schedule'} element={<MySchedule/>}/>
        </Routes>
    </Navigation>;

    const ClientsNavigation = <UserNavigation>
        <Routes>
            <Route exact path={'/'} element={<Navigate to="/new"/>}/>
            <Route exact path={'/*'} element={<Main/>}/>
            <Route exact path={'/account'} element={<Account/>}/>
            <Route exact path={'/history'} element={<MyAppointments/>}/>
            <Route exact path={'/new'} element={<NewAppointment/>}/>
            <Route exact path={'/new/staff'} element={<SelectStaffPage/>}/>
            <Route exact path={'/new/service'} element={<SelectServicePage/>}/>
            <Route exact path={'/new/time'} element={<SelectTimePage/>}/>
        </Routes>
    </UserNavigation>;

    return (
        <>
            {!accountInfo.account_type ? Loading
                : accountInfo?.account_type === 10 || accountInfo?.account_type === 5 ? StaffNavigation
                    : ClientsNavigation}
        </>

    );
}

export default AdminArea;