import { Button, CircularProgress, Container } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { getUser } from '../../../../utils/api';
import Schedule from '../../../../components/Schedule';
import FastSchedulingDialog from '../../../../components/Dialogs/FastSchedulingDialog';
import CreateAppointmentDialog from '../../../../components/Dialogs/CreateAppointmentDialog';
import BackLink from '../../../../components/BackLink';

export default function SchedulePage() {
    const params = useParams();
    const [loading, setLoading] = useState(true);
    const [modal, setModal] = useState(null);
    const [user, setUser] = useState({});
    const [date, setDate] = useState(new Date());

    useEffect(() => {
        setLoading(true);
        getUser(params.id)
            .then(r => {
                setUser(r.data);
                setLoading(false);
            })
            .catch(err => {
                console.error(err);
            });
    }, [params]);

    const fastScheduling = () => {
        const onCancel = () => {
            setModal(null);
        };

        const onSuccess = () => {
            setModal(null);
            setDate(new Date());
        };

        setModal(<FastSchedulingDialog staffId={user.id} onSuccess={onSuccess} onCancel={onCancel}/>);
    };

    const newAppointment = () => {
        const onCancel = () => {
            setModal(null);
        };

        const onSuccess = () => {
            setModal(null);
            setDate(new Date());
        };

        setModal(<CreateAppointmentDialog staffId={user.id} onSuccess={onSuccess} onCancel={onCancel}/>);
    };

    return (
        <Container>
            {modal}
            <BackLink to={'/staff/' + params.id}/>
            {loading ? <CircularProgress/> :
                <>
                    <h2>{user.last_name} {user.first_name} → График работы</h2>
                    <Button variant="outlined" sx={{mb: 2, mr: 2}} onClick={fastScheduling}>
                        Добавить рабочие дни
                    </Button>
                    <Button variant="outlined" sx={{mb: 2}} onClick={newAppointment}>Новая запись</Button>
                    {user.id && <Schedule staffId={user.id} initialDate={date}/>}
                </>
            }
        </Container>
    );
}