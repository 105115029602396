import {
    Container,
    Divider,
    IconButton,
    List,
    ListItemButton,
    ListItem,
    ListItemIcon,
    Typography, Box, Button, Alert, TextField
} from '@mui/material';

import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import PersonIcon from '@mui/icons-material/Person';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { format, formatInTimeZone } from 'date-fns-tz';
import { parse } from 'date-fns';
import { createAppointment } from '../../utils/api';
import LoadingButton from '@mui/lab/LoadingButton';

export default function NewAppointment() {
    const dispatch = useDispatch();
    const appointment = useSelector(state => state.appointment);
    const accountInfo = useSelector(state => state.account);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        if (!appointment.staff?.id) {
            dispatch({ type: 'APPOINTMENT_CLEAR_SERVICE' });
            dispatch({ type: 'APPOINTMENT_CLEAR_TIME' });
        } else if (!appointment.service?.id) {
            dispatch({ type: 'APPOINTMENT_CLEAR_TIME' });
        }
    }, []);

    const save = () => {
        if (!appointment.staff?.id) return setError('Не выбран инструктор');
        else if (!appointment.service?.id) return setError('Не выбрана услуга');
        else if (!appointment.time?.date) return setError('Не выбрана дата');
        else if (!appointment.time?.time) return setError('Не выбрано время');

        setLoading(true);

        createAppointment(accountInfo.id, appointment.staff.id, appointment.service.id, appointment.time.date, appointment.time.time, appointment.comment)
            .then(r => {
                console.log('[createAppointment]', r.data);
                setLoading(false);
                dispatch({ type: 'OPEN_SNACKBAR', severity: 'success', message: 'Вы успешно записались!' });
                dispatch({ type: 'CLEAR_APPOINTMENT_DATA' });
                navigate('/history');
            })
            .catch(err => {
                console.error(err);
                setLoading(false);
                setError(err.response.data.message || 'Произошла ошибка ' + err.response.status);
            });
    };

    return (
        <Container maxWidth="md">
            <h1>Записаться</h1>
            <List>
                <ListItem
                    disableGutters divider
                    secondaryAction={
                        !appointment.staff ? <IconButton onClick={() => navigate('staff')}>
                            <ArrowForwardIosIcon fontSize="small"/>
                        </IconButton> : <IconButton onClick={() => dispatch({ type: 'APPOINTMENT_CLEAR_STAFF' })}>
                            <RemoveCircleIcon fontSize="small"/>
                        </IconButton>
                    }
                >
                    <ListItemButton onClick={() => navigate('staff')}>
                        <ListItemIcon><PersonIcon/></ListItemIcon>
                        <Box>
                            <Typography variant="body1" sx={{ fontSize: 18, fontWeight: 400 }}>Инструктор</Typography>
                            <Typography variant="subtitle1" color="gray">
                                {appointment.staff ? `${appointment.staff.last_name} ${appointment.staff.first_name}`
                                    : 'Не выбран'}</Typography>
                        </Box>
                    </ListItemButton>
                </ListItem>
                <ListItem
                    disableGutters divider
                    secondaryAction={
                        !appointment.service ?
                            <IconButton disabled={!appointment.staff} onClick={() => navigate('service')}>
                                <ArrowForwardIosIcon fontSize="small"/>
                            </IconButton> : <IconButton disabled={!appointment.staff}
                                                        onClick={() => dispatch({ type: 'APPOINTMENT_CLEAR_SERVICE' })}>
                                <RemoveCircleIcon fontSize="small"/>
                            </IconButton>
                    }
                >
                    <ListItemButton disabled={!appointment.staff} onClick={() => navigate('service')}>
                        <ListItemIcon><DirectionsCarIcon/></ListItemIcon>
                        <Box>
                            <Typography variant="body1" sx={{ fontSize: 18, fontWeight: 400 }}>Услуга</Typography>
                            <Typography variant="subtitle1" color="gray">
                                {appointment.service ? `${appointment.service.title} (${appointment.service.duration_minutes} минут)`
                                    : 'Не выбрана'}</Typography>
                        </Box>
                    </ListItemButton>
                </ListItem>
                <ListItem
                    disableGutters divider
                    secondaryAction={
                        !appointment.time ?
                            <IconButton disabled={!appointment.service} onClick={() => navigate('time')}>
                                <ArrowForwardIosIcon fontSize="small"/>
                            </IconButton> : <IconButton disabled={!appointment.service}
                                                        onClick={() => dispatch({ type: 'APPOINTMENT_CLEAR_TIME' })}>
                                <RemoveCircleIcon fontSize="small"/>
                            </IconButton>
                    }
                >
                    <ListItemButton disabled={!appointment.service} onClick={() => navigate('time')}>
                        <ListItemIcon><AccessTimeIcon/></ListItemIcon>
                        <Box>
                            <Typography variant="body1" sx={{ fontSize: 18, fontWeight: 400 }}>Дата и время</Typography>
                            <Typography variant="subtitle1" color="gray">
                                {appointment.time ? `${format(new Date(appointment.time.date), 'dd.MM.yyyy')} (${formatInTimeZone(parse(appointment.time.time, 'HH:mm:ssx', new Date()), 'Asia/Yekaterinburg', 'HH:mm')})` : 'Не выбрано'}</Typography>
                        </Box>
                    </ListItemButton>
                </ListItem>
            </List>
            <TextField sx={{ mt: 2, mb: 1 }} fullWidth variant="outlined" label="Комментарий к записи" multiline
                       minRows={2} value={appointment.comment}
                       onChange={(e) => dispatch({ type: 'APPOINTMENT_SET_COMMENT', payload: e.target.value })}/>
            <LoadingButton loading={loading} fullWidth color="primary"
                           variant="contained" size="large" sx={{ mt: 3 }} onClick={save}
                           disabled={!appointment.staff || !appointment.service || !appointment.time}>Записаться</LoadingButton>

            {(appointment.staff || appointment.service) &&
                <LoadingButton loading={loading} fullWidth color="primary" variant="text" sx={{ mt: 1 }}
                               onClick={() => dispatch({ type: 'CLEAR_APPOINTMENT_DATA' })}>Отменить</LoadingButton>
            }

            {error && <Alert severity="error" sx={{ mt: 3, mb: 1, width: '90%' }}>{error}</Alert>}
        </Container>
    );
}
