import { Alert, Avatar, Box, Button, Container, Link, TextField, Typography } from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import React, { useEffect, useState } from 'react';
import { auth, register } from '../../../utils/api';
import { Navigate, useNavigate } from 'react-router-dom';

export default function Register() {
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [phone, setPhone] = useState('');
    const [password, setPassword] = useState('');
    const [password2, setPassword2] = useState('');

    const [error, setError] = useState(null);

    const handleSubmit = () => {
        setError(null);

        const parsedMail = (email.match(/.+@.+\..+/gmi) || [])[0] || null;
        if (!parsedMail) return setError('Некорректный Email адрес');

        if (!phone || phone?.length < 0) return setError('Не указан номер телефона');
        if (!firstName || firstName?.length < 0) return setError('Введите имя');
        if (!lastName || lastName?.length < 0) return setError('Введите фамилию');
        if (!password || password?.length < 8) return setError('Минимальная длина пароля: 8 символов');
        if (password !== password2) return setError('Пароли не совпадают');

        register(parsedMail, firstName, lastName, phone, password)
            .then(r => {
                console.log(r.data);
                localStorage.setItem('refresh_token', r.data.refresh_token);
                localStorage.setItem('access_token', r.data.access_token);

                console.log('Авторизован');
                navigate('/');
            })
            .catch(err => {
                console.error(err);
                setError(err.response.data.message || 'Произошла ошибка ' + err.response.status);
            });
    };

    const authByEnter = (e) => e.key === 'Enter' && handleSubmit();

    return (
        <Container component="main" maxWidth="xs">
            {localStorage.access_token && localStorage.refresh_token && <Navigate to="/"/>}
            <Box
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center'
                }}
            >
                <Typography component="h1" variant="h5" color="primary" sx={{fontWeight: 500, mb: 1}}>
                    RuliKZ CRM
                </Typography>
                <Typography variant="body1">Система для онлайн-записи в автошколе</Typography>
                <Avatar sx={{m: 1, mt: 5, bgcolor: 'secondary.main'}}><LockOutlinedIcon/></Avatar>
                <Typography component="h1" variant="h5">Регистрация</Typography>
                <Link href="/login" mt={1} variant="body2">Уже есть аккаунт? Войти</Link>
                <Box sx={{mt: 1}}>
                    <TextField
                        margin="normal"
                        fullWidth
                        label="Email адрес"
                        value={email}
                        onChange={(e) => setEmail(e.currentTarget.value)}
                        type="email"
                        autoComplete="email"
                        autoFocus
                    />
                    <TextField
                        margin="dense"
                        fullWidth
                        label="Телефон"
                        type="phone"
                        value={phone}
                        onChange={(e) => setPhone(e.currentTarget.value)}
                    />
                    <TextField
                        margin="dense"
                        fullWidth
                        sx={{mt: 3}}
                        label="Фамилия"
                        value={lastName}
                        onChange={(e) => setLastName(e.currentTarget.value)}
                    />
                    <TextField
                        margin="dense"
                        fullWidth
                        label="Имя"
                        value={firstName}
                        onChange={(e) => setFirstName(e.currentTarget.value)}
                    />
                    <TextField
                        margin="dense"
                        sx={{mt: 3}}
                        fullWidth
                        value={password}
                        onChange={(e) => setPassword(e.currentTarget.value)}
                        label="Пароль"
                        type="password"
                    />
                    <TextField
                        margin="dense"
                        fullWidth
                        value={password2}
                        onChange={(e) => setPassword2(e.currentTarget.value)}
                        label="Повтор пароля"
                        type="password"
                    />
                    {error && <Alert severity="error" sx={{mt: 3, mb: 1, width: '90%'}}>{error}</Alert>}
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        onClick={handleSubmit}
                        sx={{mt: 2, mb: 5}}
                    >
                        Создать аккаунт
                    </Button>
                </Box>
            </Box>
        </Container>
    );
}