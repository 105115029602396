import {
    Alert,
    Box,
    Button, Chip, Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    LinearProgress, Link,
    TextField,
    Typography
} from '@mui/material';
import { Autocomplete } from '@mui/material';
import AppointmentDatePicker from '../AppointmentDatePicker';
import LoadingButton from '@mui/lab/LoadingButton';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
    deleteAppointment,
    deleteSchedule,
    editAppointment,
    getAppointment,
    getServicesList,
    searchUsers
} from '../../utils/api';
import { format, parse } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';
import CreateAccountDialog from './CreateAccountDialog';
import AppointmentTimeDialog from './AppointmentTimeDialog';
import ru from 'date-fns/locale/ru';
import { ConfirmationDialog } from '../ConfirmationDialog';
import { useNavigate } from 'react-router-dom';

export default function EditAppointmentDialog({ onCancel, onSuccess, appointmentId }) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
    const [modal, setModal] = useState(null);
    const [data, setData] = useState(null);

    const [selectedStatus, setSelectedStatus] = useState(null);
    const [description, setDescription] = useState('');
    const [comment, setComment] = useState('');

    const [staffList, setStaffList] = useState([]);
    const [selectedStaff, setSelectedStaff] = useState(null);

    const [clientsList, setClientsList] = useState([]);
    const [selectedClient, setSelectedClient] = useState(null);

    const [servicesList, setServicesList] = useState([]);
    const [selectedService, setSelectedService] = useState(null);

    const [selectedDate, setSelectedDate] = useState(null);
    const [selectedTime, setSelectedTime] = useState(null);
    const [endTime, setEndTime] = useState(null);

    const chips = {
        1: <Chip label="Новая запись" color="primary" sx={{ mt: 0.5 }}/>,
        2: <Chip label="Запись подтверждена" color="success" sx={{ mt: 0.5 }}/>,
        3: <Chip label="Запись отменена" color="error" sx={{ mt: 0.5 }}/>
    };

    const statuses = {
        1: 'Новая запись',
        2: 'Запись подтверждена',
        3: 'Запись отменена'
    };

    useEffect(() => {
        setLoading(true);
        getAppointment(appointmentId)
            .then(r => {
                const d = r.data.data;
                console.log('getAppointment', d);
                setLoading(false);
                setData(r.data.data);
                setSelectedDate(format(new Date(d.date), 'yyyy-MM-dd'));
                setSelectedTime(d.start_time);
                setEndTime(d.end_time);
                setSelectedStatus(d.status);
                setDescription(d.description || '');
                setComment(d.comment || 'Нет');
                console.log('status', d.status);
            })
            .catch(err => {
                console.error(err);
            });
    }, [appointmentId]);

    useEffect(() => {
        searchUsers({ account_type: 5, count: 500, sc: 'id', sd: 0 })
            .then(r => {
                console.log('Search Staff Users', r.data);
                setStaffList(r.data.rows);
                if (data?.staff.id) {
                    const staff = r.data.rows.filter(i => i.id === data.staff.id)[0];
                    if (staff) setSelectedStaff(staff);
                }
            });
    }, [data]);

    useEffect(() => {
        searchUsers({ account_type: 1, count: 500, sc: 'id', sd: 0 })
            .then(r => {
                console.log('Search Clients', r.data);
                setClientsList(r.data.rows);
                if (data?.client.id) {
                    const client = r.data.rows.filter(i => i.id === data.client.id)[0];
                    if (client) setSelectedClient(client);
                }
            });
    }, [data]);

    useEffect(() => {
        setSelectedService(null);
        if (selectedStaff?.id) {
            getServicesList(selectedStaff.id)
                .then(r => {
                    console.log('getServicesList', r.data);
                    setServicesList(r.data.services);
                    if (data?.service.id) {
                        const service = r.data.services.filter(i => i.id === data.service.id)[0];
                        if (service) setSelectedService(service);
                    }
                });
        }
    }, [selectedStaff]);

    const changeTime = () => {
        const onCancel = () => {
            setModal(null);
        };

        const onSuccess = (date, time) => {
            setSelectedDate(date);
            setSelectedTime(time);
            setEndTime(null);
            setModal(null);
        };

        setModal(<AppointmentTimeDialog appointmentId={appointmentId} staffId={selectedStaff.id}
                                        serviceId={selectedService.id} onCancel={onCancel} onSuccess={onSuccess}/>);
    };

    const save = () => {
        setLoading(true);
        editAppointment(appointmentId, {
            clientId: selectedClient.id,
            staffId: selectedStaff.id,
            serviceId: selectedService.id,
            date: selectedDate,
            time: selectedTime,
            status: selectedStatus,
            description
        })
            .then(r => {
                console.log('editAppointment', r.data);
                setLoading(false);
                dispatch({ type: 'OPEN_SNACKBAR', severity: 'success', message: 'Запись успешно изменена!' });
                onSuccess(selectedDate);
            })
            .catch(err => {
                console.error(err);
                setLoading(false);
                setError(err.response.data.message || 'Произошла ошибка ' + err.response.status);
            });
    };

    const deleteAction = () => {
        const cancel = () => {
            setModal(null);
        };

        const confirm = () => {
            deleteAppointment(appointmentId)
                .then(r => {
                    console.log('[deleteAppointment]', r);
                    setModal(null);
                    dispatch({
                        type: 'OPEN_SNACKBAR',
                        severity: 'success',
                        message: `Запись ${r.data.id} успешно удалена!`
                    });
                    onSuccess(selectedDate);
                })
                .catch(err => {
                    console.error(err);
                    setModal(null);
                });
        };

        setModal(<ConfirmationDialog
            title={'Удаление записи'}
            description={'Вы действительно хотите удалить запись #' + appointmentId + '?'}
            cancel={{ title: 'Отменить', color: 'primary', action: cancel }}
            confirm={{ title: 'Удалить', color: 'error', action: confirm }}
        />);
    };

    const Content = data && <>
        <Typography>
            <b>Дата
                создания:</b> {data.created_at ? formatInTimeZone(data.created_at, 'Asia/Yekaterinburg', 'dd.MM.yyyy HH:mm') : '-'}
        </Typography>
        <Typography>
            <b>Дата
                обновления:</b> {data.updated_at ? formatInTimeZone(data.updated_at, 'Asia/Yekaterinburg', 'dd.MM.yyyy HH:mm') : '-'}
        </Typography>
        {chips[selectedStatus]}
        <Autocomplete
            value={selectedStatus}
            options={[1, 2, 3]}
            sx={{ mt: 3, mb: 2 }}
            onChange={(event, newValue) => setSelectedStatus(newValue)}
            getOptionLabel={(option) => statuses[option]}
            renderTags={(data) => {
                console.log('tags', data);
            }}
            renderInput={(params) => <TextField {...params} label="Статус записи" value={'123'} fullWidth/>}
            renderOption={(props, option) => (
                <Box component="li" {...props}>
                    {chips[option]}
                </Box>
            )}
        />
        <TextField label="Заметки к записи (видны только вам)" multiline minRows={2} value={description} fullWidth
                   sx={{ mb: 2 }}
                   onChange={e => setDescription(e.currentTarget.value)}/>
        <Typography variant="h6">Клиент</Typography>
        <Typography sx={{ mt: 1 }}>
            <Link underline="none" sx={{ cursor: 'pointer' }}
                  onClick={() => navigate('/clients/' + selectedClient?.id)}>{`${selectedClient?.last_name || ''} ${selectedClient?.first_name || ''} ${selectedClient?.patronymic || ''}`}
            </Link>
        </Typography>
        <Typography sx={{ mt: 0.5, mb: 1 }}>
            <b>Комментарий к записи:</b> {comment}
        </Typography>
        <Typography>
            <Link href={'tel:' + selectedClient?.phone}>{selectedClient?.phone}</Link>
        </Typography>
        <Typography sx={{ mt: 0.5, mb: 2 }}>
            <Link href={'mailto:' + selectedClient?.email}>{selectedClient?.email}</Link>
        </Typography>

        <Typography variant="h6">Инструктор</Typography>
        <Typography sx={{ mt: 1, mb: 1 }}>
            <Link underline="none" sx={{ cursor: 'pointer' }}
                  onClick={() => navigate('/staff/' + selectedStaff?.id)}>{`${selectedStaff?.last_name || ''} ${selectedStaff?.first_name || ''} ${selectedStaff?.patronymic || ''}`}
            </Link>
        </Typography>
        <Typography>
            <Link href={'tel:' + selectedStaff?.phone}>{selectedStaff?.phone}</Link>
        </Typography>
        <Typography sx={{ mt: 0.5, mb: 0.5 }}>
            <Link href={'mailto:' + selectedStaff?.email}>{selectedStaff?.email}</Link>
        </Typography>
        <Autocomplete
            value={selectedService}
            options={servicesList}
            disabled={!selectedStaff || !selectedClient}
            sx={{ mt: 2, mb: 3 }}
            onChange={(event, newValue) => {
                setSelectedService(newValue);
                setSelectedDate(null);
                setSelectedTime(null);
                setEndTime(null);
            }}
            getOptionLabel={(option) => `${option.title} (${option.duration_minutes} мин.)`}
            renderInput={(params) => <TextField {...params} label="Услуга" fullWidth/>}
            renderOption={(props, option) => (
                <Box component="li" {...props}>
                    <Box>
                        <Typography>{option.title}</Typography>
                        <Typography variant="subtitle2" style={{ color: 'gray', display: 'block' }}>
                            {option.duration_minutes} минут
                        </Typography>
                    </Box>
                </Box>
            )}
        />
        <Typography variant="h6">Дата и время</Typography>
        {selectedDate && selectedTime ? <>
            <Typography>Дата: {format(new Date(selectedDate), 'dd MMMM yyyy', { locale: ru })}</Typography>
            <Typography>Начало: {formatInTimeZone(parse(selectedTime, 'HH:mm:ssx', new Date()), 'Asia/Yekaterinburg', 'HH:mm')}</Typography>
            {endTime &&
                <Typography>Конец: {formatInTimeZone(parse(endTime, 'HH:mm:ssx', new Date()), 'Asia/Yekaterinburg', 'HH:mm')}</Typography>}
            <Button variant="contained" onClick={changeTime} sx={{ mt: 1 }}>Изменить время</Button>
        </> : <>
            <Typography color="error"><b>Не выбрано время записи!</b></Typography>
            <Button variant="contained" onClick={changeTime} sx={{ mt: 1 }}>Выбрать время</Button>
        </>}

        <Button variant="outlined" color="error" sx={{ display: 'block', mt: 3 }} onClick={deleteAction}>
            Удалить запись
        </Button>
    </>;

    return (
        <Dialog open={true} onClose={onCancel} maxWidth="xs" scroll="body" disableEnforceFocus fullWidth>
            {modal}
            <DialogTitle>{loading ? 'Загрузка...' : 'Запись #' + data?.id}</DialogTitle>
            <DialogContent>
                {Content}
                {/*{selectedService && <AppointmentDatePicker serviceId={selectedService.id} staffId={selectedStaff.id}*/}
                {/*                                           onChange={onSelectTime}/>}*/}
                {error && <Alert severity="error" sx={{ mt: 3, mb: 1, maxWidth: 800 }}>{error}</Alert>}
            </DialogContent>
            <DialogActions>
                <Button onClick={onCancel}>Закрыть</Button>
                <LoadingButton onClick={save} color="primary" variant="contained" loading={loading}
                               disabled={!selectedClient || !selectedStaff || !selectedStatus || !selectedService || !selectedDate || !selectedTime}>
                    Сохранить
                </LoadingButton>
            </DialogActions>
            {loading && <LinearProgress style={{ height: 6 }}/>}
        </Dialog>
    );
}
